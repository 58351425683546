import { Box, Button, Image, Text } from '@gluestack-ui/themed';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type { FC } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';

import BackgroundSVG from '../assets/background.svg';
import AnalysisHistoryFilterBar from '../components/analysis/AnalysisHistoryFilterBar';
import { AnalysisHistoryList } from '../components/analysis/AnalysisHistoryList';
import { AppDescription } from '../components/analysis/AppDescription';
import { TranslationContext } from '../contexts/translations/Translation';
import { UserContext } from '../contexts/User';
import type { AnalysisListDTO } from '../generated/client-api-client';
import { AnalysisDTO, AnalysisService } from '../generated/client-api-client';
import type { IAnalysisListPageProps } from '../routes/Routes';
import { COLORS } from '../theme/colors';

const NUMBER_RESULTS_PAGE = 10;
const UNCONFIRMED_STATUS = [AnalysisDTO.status.ERROR, AnalysisDTO.status.COMPLETED, AnalysisDTO.status.IDLE, AnalysisDTO.status.PROCESSING];
export const UNCONFIRMED = 'UNCONFIRMED';

export const AnalysisListPage: FC<IAnalysisListPageProps> = (props: IAnalysisListPageProps) => {
  const { refresh, testID, watchAnalysisIds } = props.route.params;
  const { i18n } = useContext(TranslationContext);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [status, setStatus] = useState<AnalysisDTO.status | '' | typeof UNCONFIRMED>('');
  const [search, setSearch] = useState<string>('');
  const [watchedAnalysisIds, setWatchedAnalysisIds] = useState<string[]>(watchAnalysisIds ?? []);
  const [allAnalysisData, setAllAnalysisData] = useState<AnalysisListDTO>({ count: 0, data: [] });

  const user = useContext(UserContext);
  const prevFilterRef = useRef({ search: '', status: '', pageNumber: -1 });

  const {
    data: analysisList,
    isLoading: isAnalysisLoading,
    refetch: refetchAnalysistList,
  } = useQuery<AnalysisDTO[], any, any>({
    queryKey: ['analysis', pageNumber, user, status, search],
    queryFn: async () => {
      let codeSearch;
      let nameSearch;
      if (search) {
        if (search.startsWith('BIO_')) {
          codeSearch = search;
        } else {
          nameSearch = search;
        }
      }
      const newParams = { search, status, pageNumber };
      const prevParams = prevFilterRef.current;

      // Params changed so we start from 0
      if (newParams.search !== prevParams.search || newParams.status !== prevParams.status) {
        setPageNumber(0);
      }

      const response = await AnalysisService.analysisControllerSearch(
        user!.currentWorkspace!.id!,
        codeSearch,
        undefined,
        undefined,
        undefined,
        nameSearch,
        status === UNCONFIRMED ? UNCONFIRMED_STATUS : undefined,
        pageNumber,
        NUMBER_RESULTS_PAGE,
        'createdAt',
        'desc',
        status && status !== UNCONFIRMED ? (status as AnalysisDTO.status) : undefined,
      );

      setWatchedAnalysisIds(
        response?.data
          ?.filter((analysis) => watchAnalysisIds?.includes(analysis.id) && (analysis.status === AnalysisDTO.status.PROCESSING || analysis.status === AnalysisDTO.status.IDLE))
          ?.map((analysis) => analysis.id),
      );

      let results: AnalysisListDTO = response;

      if (!(newParams.search !== prevParams.search || newParams.status !== prevParams.status) && prevParams.pageNumber !== newParams.pageNumber && prevParams.pageNumber !== -1) {
        results = {
          count: response.count,
          data: [...allAnalysisData.data, ...response.data],
        };
      }

      prevFilterRef.current = newParams;
      setAllAnalysisData(results);
      return results.data;
    },
    refetchInterval: watchedAnalysisIds.length === 0 ? 15_000 : 2_000,
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    prevFilterRef.current = { search: '', status: '', pageNumber: -1 };
    setSearch('');
    setStatus('');
    setAllAnalysisData({ count: 0, data: [] });
    refetchAnalysistList();
  }, [props]);

  return (
    <Box style={styles.layout}>
      <Image crossOrigin="anonymous" alt="" style={styles.backgroundImage} source={BackgroundSVG} />
      <AppDescription />
      <AnalysisHistoryFilterBar status={status} setStatus={setStatus} setSearch={setSearch} search={search} />
      <AnalysisHistoryList isLoading={isAnalysisLoading} data={analysisList} />
      {allAnalysisData.count > NUMBER_RESULTS_PAGE * (1 + pageNumber) && (
        <Button
          style={{ marginVertical: 20, alignSelf: 'center' }}
          onPress={() => {
            setPageNumber(pageNumber + 1);
          }}
        >
          <Text fontWeight="bold" color="white">
            {i18n?.t('show_more')}
          </Text>
        </Button>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  layout: {
    backgroundColor: COLORS.FOUNDATION_PRIMARY,
    height: '100%',
    paddingHorizontal: '10%',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'scroll',
  },
  backgroundImage: {
    position: 'absolute',
    width: 450,
    height: 450,
    top: 0,
    right: -60,
    zIndex: -1,
  },
});
