/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AnalysisReportQueryParams = {
    createdAtDateBegin?: string;
    createdAtDateEnd?: string;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
    sortOrder?: AnalysisReportQueryParams.sortOrder;
    status?: AnalysisReportQueryParams.status;
    userId?: string;
};

export namespace AnalysisReportQueryParams {

    export enum sortOrder {
        ASC = 'asc',
        DESC = 'desc',
    }

    export enum status {
        IDLE = 'IDLE',
        PROCESSING = 'PROCESSING',
        COMPLETED = 'COMPLETED',
        ERROR = 'ERROR',
    }


}

