import { Divider, HStack, Icon, Pressable, Spinner, Text } from '@gluestack-ui/themed';
import { Picker } from '@react-native-picker/picker';
import type { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { HomeIcon } from 'lucide-react-native';
import type { FC } from 'react';
import { useContext } from 'react';

import { MediaContext } from '../../contexts/media/Media';
import { TranslationContext } from '../../contexts/translations/Translation';
import { UserContext } from '../../contexts/User';
import type { RootStackParamList } from '../../routes/Routes';
import { Routes } from '../../routes/Routes';
import { COLORS } from '../../theme/colors';
import { HeaderLeft } from './HeaderLeft';
import { HeaderRight } from './HeaderRight';

export interface IHeaderProps {
  route: any;
  testID?: string;
}

export const Header: FC<IHeaderProps> = (props: IHeaderProps) => {
  const { closeStream } = useContext(MediaContext)!;

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { i18n } = useContext(TranslationContext);
  const user = useContext(UserContext);

  const onHomePress = async () => {
    closeStream();
    navigation.navigate(Routes.ANALYSIS_LIST, { refresh: true });
  };

  return (
    <HStack style={{ alignItems: 'center', backgroundColor: COLORS.FOUNDATION_TERCIARY_100, height: 70, justifyContent: 'space-between', paddingHorizontal: 20 }}>
      <HeaderLeft />
      <HStack style={{ alignItems: 'center' }}>
        <Pressable onPress={onHomePress}>
          <HStack>
            <Icon as={HomeIcon} style={{ color: 'white', marginRight: 10, marginTop: 2 }} />
            <Text style={{ color: 'white' }} testID={`${props?.testID || ''}${Header.name}_home`}>
              {i18n?.t('layout.nav-bar.home')}
            </Text>
          </HStack>
        </Pressable>
        <Divider style={{ backgroundColor: 'white', height: 30, width: 1, marginHorizontal: 10 }} />
        {user?.isLoading || user?.isScansInformationLoading ? (
          <Spinner testID={`${props.testID ?? ''}${Header.name}_isLoadingScanUsage`} />
        ) : (
          <Text style={{ color: 'white' }} testID={`${props?.testID || ''}${Header.name}_scanUsage`}>
            {i18n?.t('layout.nav-bar.scan-usage', { count: user?.scansInformation?.consumed, total: user?.scansInformation?.total })}
          </Text>
        )}
        <Divider style={{ backgroundColor: 'white', height: 30, width: 1, marginHorizontal: 10 }} />
        {user?.isLoading ? (
          <Spinner testID={`${props.testID ?? ''}${Header.name}_isLoadingUser`} />
        ) : (
          <Picker
            testID={`${props?.testID ?? ''}${Header.name}_workspacePicker`}
            selectedValue={user?.currentWorkspace?.id}
            onValueChange={(itemValue) => {
              user?.setCurrentWorkspace(itemValue);
              onHomePress();
            }}
            style={{ backgroundColor: COLORS.FOUNDATION_TERCIARY_100, color: 'white', borderRadius: 5, height: 30, width: 200 }}
          >
            {Array.isArray(user?.workspaceList) &&
              user?.workspaceList.map((workspace) => (
                <Picker.Item testID={`${props?.testID ?? ''}${Header.name}_workspaceItem_${workspace.id}`} key={workspace.id} label={workspace.name} value={workspace.id} />
              ))}
          </Picker>
        )}
      </HStack>
      <HeaderRight />
    </HStack>
  );
};
