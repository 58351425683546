import { createContext } from "react";

export interface Media {
  cameraList: MediaDeviceInfo[] | undefined;
  changeCamera: (deviceId: string) => void;
  closeStream: () => void;
  hasCameraStarted: boolean;
  isCameraAllowed: boolean | undefined;
  mediaStream: MediaStream | undefined;
  selectedCamera: string | undefined;
  setMediaStream: (tracks: MediaStream | undefined) => void;
  setSelectedCamera: (camera: string | undefined) => void;
  setVideo: (video: HTMLVideoElement | undefined) => void;
  retakePhoto: (setPhotoUriList: (photoUriList: string[]) => void) => void;
  startCamera: () => void;
  takePhoto: (setPhotoUriList: (photoUriList: string[]) => void) => void
  video: HTMLVideoElement | undefined;
}

export const MediaContext = createContext<Media>({
  cameraList: undefined,
  changeCamera: () => {},
  closeStream: () => {},
  hasCameraStarted: false,
  isCameraAllowed: undefined,
  mediaStream: undefined,
  selectedCamera: undefined,
  setMediaStream: () => {},
  setSelectedCamera: () => {},
  setVideo: () => {},
  retakePhoto: () => {},
  startCamera: () => {},
  takePhoto: () => {},
  video: undefined,
});
