import { I18n, useMakePlural } from 'i18n-js';
import { en, fr } from 'make-plural';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import englishTranslations from '../../translations/en/common.json';
import frenchTranslations from '../../translations/fr/common.json';
import { TranslationContext } from './Translation';

export interface ITranslationContainerProps {
  children: any;
}

export const TranslationContainer: FC<ITranslationContainerProps> = ({ children }: ITranslationContainerProps) => {
  const [i18nInstance, setI18nInstance] = useState<I18n>(new I18n(undefined, { locale: 'en' }));

  useEffect(() => {
    const f = async () => {
      const lang = (await AsyncStorage.getItem("lang")) ?? 'en';
      i18nInstance.locale = lang
      i18nInstance.translations = { fr: frenchTranslations, en: englishTranslations };
      i18nInstance.enableFallback = true;
      const newObj: I18n = { ...i18nInstance, locale: lang } as I18n;
      setI18nInstance(newObj);
    };
    f();
  }, []);

  const changeLanguage = async (locale: 'en' | 'fr'): Promise<void> => {
    await AsyncStorage.setItem("lang", locale);
    const newObj: I18n = { ...i18nInstance, locale } as I18n;
    setI18nInstance(newObj);
  };

  return (
    <TranslationContext.Provider
      value={{
        i18n: i18nInstance,
        changeLanguage,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
