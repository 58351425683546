import { ButtonText, Button, HStack, Icon, Menu, MenuItemLabel, Text, Spinner } from '@gluestack-ui/themed';
import { ChevronRight, GlobeIcon, LogOutIcon } from 'lucide-react-native';
import { useContext, type FC } from 'react';
import { COLORS } from '../../theme/colors';
import { MenuItem } from '@gluestack-ui/themed';
import { AuthContext } from '../../contexts/session/Auth';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../routes/Routes';
import { UserContext } from '../../contexts/User';
import { TranslationContext } from '../../contexts/translations/Translation';

export interface IHeaderRightProps {}

export const HeaderRight: FC = (props: IHeaderRightProps) => {
  const { i18n, changeLanguage } = useContext(TranslationContext);
  const auth = useContext(AuthContext);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const user = useContext(UserContext);

  return (
    <HStack style={{ alignItems: 'center' }}>
      <Text color="white" style={{ marginRight: 5 }}>
        {user?.user?.firstName ?? i18n?.t('common.you')}
      </Text>
      <Icon style={{ color: 'white', marginRight: 5 }} as={ChevronRight} />
      <Menu
        selectionMode="single"
        onSelectionChange={(keys: any) => {
          if(keys?.currentKey === 'ToggleLanguage'){
            i18n?.locale === 'en' ? changeLanguage('fr') : changeLanguage('en');
            const currentRoute = navigation.getState().routes[navigation.getState().routes.length - 1];
            navigation.navigate(currentRoute.name as keyof RootStackParamList, { ...currentRoute.params, refresh: true } as any)
          }
          if (keys?.currentKey === 'LogOut') {
            auth?.logOut();
          }
        }}
        trigger={({ ...triggerProps }) => {
          return (
            <Button
              {...triggerProps}
              w={40}
              h={40}
              borderRadius='$full'
              backgroundColor={COLORS.PRIMARY[100]}
            >
              <ButtonText color={COLORS.PRIMARY[80]}>{`${user?.user?.firstName?.[0]}${user?.user?.lastName?.[0]}`}</ButtonText>
            </Button>
          )
        }}
      > 
        <MenuItem key="ToggleLanguage" textValue={i18n?.locale === 'en' ? 'Francais' : 'English'}>
          <Icon as={GlobeIcon} size="sm" mr="$2" />
          <MenuItemLabel size="sm">{i18n?.locale === 'en' ? 'Francais' : 'English'}</MenuItemLabel>
        </MenuItem>
        <MenuItem key="LogOut" textValue="LogOut">
          {
            !auth?.isLoading && (<Icon as={LogOutIcon} size="sm" mr="$2" />)
          }
          {
            auth?.isLoading && (<Spinner mr="$1" />)
          }
          <MenuItemLabel size="sm">{i18n?.t('layout.nav-bar.logout')}</MenuItemLabel>
        </MenuItem>
      </Menu>
    </HStack>
  );
};