/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePathogenDTO } from '../models/CreatePathogenDTO';
import type { PathogenDTO } from '../models/PathogenDTO';
import type { PathogenListDTO } from '../models/PathogenListDTO';
import type { UpdatePathogenDTO } from '../models/UpdatePathogenDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PathogenService {

    /**
     * Create
     * @param requestBody CreatePathogenDTO
     * @returns any Successful response
     * @returns PathogenDTO
     * @throws ApiError
     */
    public static pathogenControllerCreate(
        requestBody?: CreatePathogenDTO,
    ): CancelablePromise<any | PathogenDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pathogens',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @param name
     * @param pageNumber
     * @param pageSize
     * @param parentId
     * @param type
     * @returns PathogenListDTO
     * @throws ApiError
     */
    public static pathogenControllerSearch(
        name?: string,
        pageNumber?: number,
        pageSize?: number,
        parentId?: string,
        type?: string,
    ): CancelablePromise<PathogenListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pathogens',
            query: {
                'name': name,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'parentId': parentId,
                'type': type,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static pathogenControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pathogens/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Read one
     * @param id
     * @returns PathogenDTO
     * @throws ApiError
     */
    public static pathogenControllerReadOne(
        id: string,
    ): CancelablePromise<PathogenDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pathogens/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update
     * @param id
     * @param requestBody UpdatePathogenDTO
     * @returns PathogenDTO
     * @throws ApiError
     */
    public static pathogenControllerUpdate(
        id: string,
        requestBody?: UpdatePathogenDTO,
    ): CancelablePromise<PathogenDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/pathogens/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search inconsistencies
     * @param name
     * @param pageNumber
     * @param pageSize
     * @param parentId
     * @param type
     * @returns PathogenListDTO
     * @throws ApiError
     */
    public static pathogenControllerSearchInconsistencies(
        name?: string,
        pageNumber?: number,
        pageSize?: number,
        parentId?: string,
        type?: string,
    ): CancelablePromise<PathogenListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pathogens/search/inconsistencies',
            query: {
                'name': name,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'parentId': parentId,
                'type': type,
            },
        });
    }

}
