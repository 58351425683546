/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentSessionDTO } from '../models/PaymentSessionDTO';
import type { PortalUrlDTO } from '../models/PortalUrlDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionService {

    /**
     * Client secret
     * @param workspaceId
     * @returns PaymentSessionDTO
     * @throws ApiError
     */
    public static subscriptionClientSecret(
        workspaceId: string,
    ): CancelablePromise<PaymentSessionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/subscription/customer-session',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Portal url
     * @param workspaceId
     * @returns any Successful response
     * @returns PortalUrlDTO
     * @throws ApiError
     */
    public static subscriptionPortalUrl(
        workspaceId: string,
    ): CancelablePromise<any | PortalUrlDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/subscription/customer-portal-url',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

}
