
export function pascalToSnake(pascalString: string) {
    return pascalString.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
      return "_" + y.toLowerCase();
    }).replace(/^_/, "");
}

export function isString(str: any): boolean {
  return typeof str === 'string';
}
