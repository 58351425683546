/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDTO } from '../models/CreateUserDTO';
import type { InviteUserDTO } from '../models/InviteUserDTO';
import type { MeUserDTO } from '../models/MeUserDTO';
import type { UpdateUserDTO } from '../models/UpdateUserDTO';
import type { UserDTO } from '../models/UserDTO';
import type { UserListDTO } from '../models/UserListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Add to workspace
     * @param workspaceId
     * @param requestBody InviteUserDTO
     * @returns any Successful response
     * @throws ApiError
     */
    public static userControllerAddToWorkspace(
        workspaceId: string,
        requestBody?: InviteUserDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/users/add-to-workspace',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create
     * @param workspaceId
     * @param requestBody CreateUserDTO
     * @returns UserDTO
     * @throws ApiError
     */
    public static userControllerCreate(
        workspaceId: string,
        requestBody?: CreateUserDTO,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/users',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @param workspaceId
     * @param email
     * @param firstName
     * @param lastName
     * @param pageNumber
     * @param pageSize
     * @param status
     * @returns UserListDTO
     * @throws ApiError
     */
    public static userControllerSearch(
        workspaceId: string,
        email?: string,
        firstName?: string,
        lastName?: string,
        pageNumber?: number,
        pageSize?: number,
        status?: 'ACTIVE' | 'ARCHIVED' | 'DELETED',
    ): CancelablePromise<UserListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/users',
            path: {
                'workspaceId': workspaceId,
            },
            query: {
                'email': email,
                'firstName': firstName,
                'lastName': lastName,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'status': status,
            },
        });
    }

    /**
     * Delete
     * @param workspaceId
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static userControllerDelete(
        workspaceId: string,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/workspaces/{workspaceId}/users/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

    /**
     * Read one
     * @param workspaceId
     * @param id
     * @returns UserDTO
     * @throws ApiError
     */
    public static userControllerReadOne(
        workspaceId: string,
        id: string,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/users/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

    /**
     * Update
     * @param workspaceId
     * @param id
     * @param requestBody UpdateUserDTO
     * @returns UserDTO
     * @throws ApiError
     */
    public static userControllerUpdate(
        workspaceId: string,
        id: string,
        requestBody?: UpdateUserDTO,
    ): CancelablePromise<UserDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/workspaces/{workspaceId}/users/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Me
     * @param workspaceId
     * @returns MeUserDTO
     * @throws ApiError
     */
    public static userControllerMe(
        workspaceId: string,
    ): CancelablePromise<MeUserDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/users/me',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Remove from workspace
     * @param workspaceId
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static userControllerRemoveFromWorkspace(
        workspaceId: string,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/users/{id}/remove-from-workspace',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

    /**
     * Reset password
     * @param workspaceId
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static userControllerResetPassword(
        workspaceId: string,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/users/{id}/reset-password',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

}
