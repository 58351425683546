/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageProcessingDTO } from '../models/ImageProcessingDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImageProcessingService {

    /**
     * Converts a TIFF image to a JPEG image
     * @param requestBody ImageProcessingDTO
     * @returns string Returns a base64 encoded JPEG image
     * @throws ApiError
     */
    public static imageProcessingControllerConvertTiffToJpg(
        requestBody?: ImageProcessingDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/image-processing/convert-tiff-to-jpg',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Converts a TIFF image to a PNG image
     * @param requestBody ImageProcessingDTO
     * @returns string Returns a base64 encoded PNG image
     * @throws ApiError
     */
    public static imageProcessingControllerConvertTiffToPng(
        requestBody?: ImageProcessingDTO,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/image-processing/convert-tiff-to-png',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
