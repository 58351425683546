/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoriesCountDTO } from '../models/CategoriesCountDTO';
import type { CreateStrainDTO } from '../models/CreateStrainDTO';
import type { StrainDTO } from '../models/StrainDTO';
import type { StrainListDTO } from '../models/StrainListDTO';
import type { UpdateStrainDTO } from '../models/UpdateStrainDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StrainService {

    /**
     * Create
     * @param requestBody CreateStrainDTO
     * @returns any Successful response
     * @returns StrainDTO
     * @throws ApiError
     */
    public static strainControllerCreate(
        requestBody?: CreateStrainDTO,
    ): CancelablePromise<any | StrainDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/strains',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @param commonName
     * @param classification
     * @param pageNumber
     * @param pageSize
     * @param scientificName
     * @param status
     * @param taxonomy
     * @returns StrainListDTO
     * @throws ApiError
     */
    public static strainControllerSearch(
        commonName?: string,
        classification?: string,
        pageNumber?: number,
        pageSize?: number,
        scientificName?: string,
        status?: 'APPROVED' | 'PENDING' | 'REJECTED',
        taxonomy?: string,
    ): CancelablePromise<StrainListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains',
            query: {
                'commonName': commonName,
                'classification': classification,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'scientificName': scientificName,
                'status': status,
                'taxonomy': taxonomy,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static strainControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/strains/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Read one
     * @param id
     * @returns StrainDTO
     * @throws ApiError
     */
    public static strainControllerReadOne(
        id: string,
    ): CancelablePromise<StrainDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update
     * @param id
     * @param requestBody UpdateStrainDTO
     * @returns StrainDTO
     * @throws ApiError
     */
    public static strainControllerUpdate(
        id: string,
        requestBody?: UpdateStrainDTO,
    ): CancelablePromise<StrainDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/strains/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search missing lineages
     * @param commonName
     * @param classification
     * @param pageNumber
     * @param pageSize
     * @param scientificName
     * @param status
     * @param taxonomy
     * @returns StrainListDTO
     * @throws ApiError
     */
    public static strainControllerSearchMissingLineages(
        commonName?: string,
        classification?: string,
        pageNumber?: number,
        pageSize?: number,
        scientificName?: string,
        status?: 'APPROVED' | 'PENDING' | 'REJECTED',
        taxonomy?: string,
    ): CancelablePromise<StrainListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/search/missing-lineages',
            query: {
                'commonName': commonName,
                'classification': classification,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'scientificName': scientificName,
                'status': status,
                'taxonomy': taxonomy,
            },
        });
    }

    /**
     * Get strain category counts
     * @returns CategoriesCountDTO
     * @throws ApiError
     */
    public static strainControllerGetStrainCategoryCounts(): CancelablePromise<CategoriesCountDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/strains/category/counts',
        });
    }

}
