import { FC, useEffect, useState } from "react";
import { Box, HStack, Image, Pressable, Text } from "@gluestack-ui/themed";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react-native";

export interface ICarouselProps {
  disableControls?: boolean;
  overrideCurrentPhotoIndex?: number;
  onPhotoChange?: (newCurrentIndex: number) => void;
  photoUriList: string[];
  testID?: string;
}

export const Carousel: FC<ICarouselProps> = (props: ICarouselProps) => {
  const { disableControls, overrideCurrentPhotoIndex, onPhotoChange, photoUriList, testID } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const nextIndex = (index: number) => (index + 1) % photoUriList.length;
  const previousIndex = (index: number) => (index - 1 + photoUriList.length) % photoUriList.length;

  const goToImage = (index: number) => {
    onPhotoChange?.(index);
    setCurrentImageIndex(index);
  };

  const nextImage = () => {
    const newIndex = nextIndex(currentImageIndex);
    onPhotoChange?.(newIndex);
    setCurrentImageIndex(newIndex);
  };

  const previousImage = () => {
    const newIndex = previousIndex(currentImageIndex);
    onPhotoChange?.(newIndex);
    setCurrentImageIndex(newIndex);
  };

  useEffect(() => {
    setCurrentImageIndex(overrideCurrentPhotoIndex ?? 0);
  }, [overrideCurrentPhotoIndex]);

  return (
    <>
      {photoUriList.length > 2 && (
        <Image testID={`${testID ?? ''}${Carousel.name}_previousImage`} alt="previous image" crossOrigin="anonymous" width={'10%'} height={'10%'} source={{ uri: photoUriList[previousIndex(currentImageIndex)] }} style={{ position: 'absolute', top: 10, left: 25, zIndex: 24 }} />
      )}
      {photoUriList.length > 1 && (
        <Image testID={`${testID ?? ''}${Carousel.name}_nextImage`} alt="next image" crossOrigin="anonymous" width={'10%'} height={'10%'} source={{ uri: photoUriList[nextIndex(currentImageIndex)] }} style={{ position: 'absolute', top: 10, right: 25, zIndex: 24 }} />
      )}
      <Image testID={`${testID ?? ''}${Carousel.name}_currentImage`} alt="current image" crossOrigin="anonymous" width={'100%'} height={'100%'} resizeMode='contain' source={{ uri: photoUriList[currentImageIndex] }} style={{ zIndex: 23 }} />
      <HStack style={{ position: 'absolute', bottom: 10, alignSelf: 'center', alignItems: 'center', zIndex: 25, gap: 10 }}>
        {disableControls ? null : (
          <Pressable testID={`${testID ?? ''}${Carousel.name}_previousImage_pressable`} onPress={previousImage}>
            <ChevronLeftIcon />
          </Pressable>
        )}
        {photoUriList.map((_, index) => (
          <Box key={index}>
            {disableControls ? (
              <Text testID={`${testID ?? ''}${Carousel.name}_circle_${index}`} style={{ color: currentImageIndex === index ? 'black' : 'gray' }}>●</Text>
            ) : (
              <Pressable testID={`${testID ?? ''}${Carousel.name}_circle_pressable_${index}`} onPress={disableControls ? () => {} : () => goToImage(index)}>
                <Text style={{ color: currentImageIndex === index ? 'black' : 'gray' }}>
                  ●
                </Text>
              </Pressable>
            )}
          </Box>
        ))}
        {disableControls ? null : (
          <Pressable testID={`${testID ?? ''}${Carousel.name}_nextImage_pressable`} onPress={nextImage}>
            <ChevronRightIcon />
          </Pressable>
        )}
      </HStack>
    </>
  );
};
