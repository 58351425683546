import { Box, Input, InputField } from "@gluestack-ui/themed";
import { FC, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { TranslationContext } from "../../contexts/translations/Translation";

export interface IFormItemDropDownProps {
  onChange: (value?: Date) => void;
  value?: Date;
  isDisabled?: boolean;
  filterDate?: (date: Date) => boolean;
}

export const FormDatePicker: FC<IFormItemDropDownProps> = ({filterDate, value, onChange, isDisabled}: IFormItemDropDownProps) => {
  const { i18n } = useContext(TranslationContext);
  
  const handleChange = (date: Date | null) => {
    onChange(date ? new Date(date) : undefined);
  };

  return (
    <Box>
      <DatePicker 
        disabled={isDisabled}
        closeOnScroll={true}
        filterDate={filterDate}
        // Need to pass the portalId to avoid element being rendered in below others
        portalId="root-portal"
        selected={value} onChange={handleChange} customInput={
          <Input>
            <InputField value={value ? value?.toLocaleDateString(i18n.locale) : ''} />
          </Input>
        }
      />
    </Box>
  );
};