export interface IAuth0Env {
  audience: string;
  clientId: string;
  domain: string;
}

export interface IEnv {
  apiBaseUrl: string;
  auth0: IAuth0Env;
  s3Host: string;
}

export const env: IEnv = {
  apiBaseUrl: process.env.EXPO_PUBLIC_API_BASE_URL!,
  auth0: {
    audience: process.env.EXPO_PUBLIC_AUTH0_AUDIENCE!,
    clientId: process.env.EXPO_PUBLIC_AUTH0_CLIENT_ID!,
    domain: process.env.EXPO_PUBLIC_AUTH0_DOMAIN!,
  },
  s3Host: process.env.EXPO_PUBLIC_S3_HOST!,
}