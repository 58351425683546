
export const TaxonomyMap = [
    "domain",
    "division",
    "class",
    "order",
    "family",
    "type",
    "species",
    "commonName", //"Common name of the strain",
    "scientificName", //"Scientific name of the strain"
]

export const CharacteristicMap = [
    "gramColoration", //"Gram staining", // gram_colorationn
    "acidoAlcooloResistant", //"Acid-fast staining",
    "capture", //"Capsule",
    "endosporeProduction", //"Endospore production",
    "oxygen", //"Oxygen",
    "minimumGrowthTemperature", //"Minimum growth temperature",
    "optimalGrowthTemperature", //"Optimum growth temperature",
    "maximumGrowthTemperature", //"Maximum growth temperature",
    "nucleotide", //"Nucleotide",
    "protein", //"Protein",
    "proteinGroup", //"Protein group",
    "taxonomy", //"Taxonomie",
    "tsa37deg", //"TSA at 37°C",
    "shape",// "Shape",// 
    "elevation", //"Elevation", 
    "border", //"Margin",
    "mp48", //"Genome size (Mb)", 
    "mp50", //"Culture medium",
    "mp51", //"Size on TSA agar at 20°C", 
    "mp52", //"Size on TSA agar at 37°C", 
    "mp53", //"Types of staining", // 
    "mp54", //"Optimal pH", // 
    "mp55", //"Carbohydrate utilization", // 
    "mp56", // "Growth on differential/selective media" // 
];

export const BiochemicalTestMap = [
    "mp01",
    "mp02",
    "mp03",
    "mp04",
    "mp05",
    "mp06",
    "mp07",
    "mp08",
    "mp09",
    "mp10",
    "mp11",
    "mp12",
    "mp13",
    "mp14",
    "mp15",
    "mp16",
    "mp17",
    "mp18",
    "mp19",
    "mp20",
    "mp21",
    "mp22",
    "mp23",
    "mp24",
    "mp25",
    "mp26",
    "mp27",
    "mp28",
    "mp29",
    "mp30",
    "mp31",
    "mp32",
    "mp33",
    "mp34",
    "mp35",
    "mp36",
    "mp37",
    "mp38",
    "mp39",
    "mp40",
    "mp41",
    "mp42",
    "mp43",
    "mp44",
    "mp45",
    "mp46",
    "mp47",
    "mp4701",
    "mp4702",
    "mp4703",
    "mp4704",
    "mp4705",
    "mp4706",
    "mp4707",
    "mp4708",
    "mp4709",
    "mp4710",
    "mp4711",
    "mp4712",
    "mp4713",
    "mp4714",
    "mp4715",
    "mp4716",
    "mp4717",
    "mp4718",
    "mp4719",
    "mp4720",
    "mp4721",
];

export const PathogeniCityMap = [
    "mp57", //"Antibiotic sensitivity/resistance", // mp62, mp57
    "mp58", //"Metabolite productions (endotoxins, exotoxins, etc.)",
    "mp59", //"Catabolism of ornithine, arginine and lysine",
    "mp60", //"Pathogenicity",
    "mp76", //"Host range",  
    "mp68", //"Mode of transmission", // mp68
    "mp63", //, "Types of diseases", // mp63
    "mp64", //"Disease", // mp64
    "mp65", //"Pathogenic agent", // mp65
    "mp66", //"Signs and symptoms", // mp66
    "mp67", //"Incubation period", // mp67
    "mp69", //"Diagnostic test", // mp69
    "mp70", //"Prevention/Treatment", //mp70
    "mp71", //"Antibacterial drugs", // mp71
    "mp72",//"Literature", //mp72
    "mp73"//"Laboratory diagnosis" //mp73
]
export const SimplifiedLiteratureMap = [
    "mp74" // mp74
]
export const SourceMap = [
    "mp75"
]