/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WorkspaceQueryParams = {
    country?: string;
    description?: string;
    name?: string;
    pageNumber?: number;
    pageSize?: number;
    status?: WorkspaceQueryParams.status;
    type?: WorkspaceQueryParams.type;
};

export namespace WorkspaceQueryParams {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }

    export enum type {
        B2B = 'B2B',
        B2C = 'B2C',
    }


}

