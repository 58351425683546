export const COLORS = {
  PRIMARY:{
    80: '#EFF5F5',
    100: '#7FC9AF',
    110: '#2A464D',
  },
  SECONDARY:{
    80: '#F9FDFE',
    90: '#E6F9FF',
    100: '#C7E7F1',
    110: '#778B91'
  },
  TERCIARY: {
    80: '#DCDEE2',
    90: '#B7BBC4',
    100: '#172540',
    110: '#111C30'
  },
  NEUTRAL:{
    0: '#FFFFFF',
    60: '#F2F2F2',
    70: '#CDCCCC',
    80: '#CBCBCB',
    90: '#4E4D4D',
    100: '#000000'
  },
  ACCENT: {
    100: '#D54D3A',
    110: '#EC5E4A'
  },
  STATUS:{
    SUCCESS: '#06C270',
    INFO: '#0063F7',
    WARNING: '#FFCC00',
    WARNING_2: '#FF3B3B'
  },
  FOUNDATION_NEUTRAL_100: '#000000',
  FOUNDATION_PRIMARY: '#EFF5F5',
  FOUNDATION_PRIMARY_100: '#7FC9AF',
  FOUNDATION_PRIMARY_200: '#2A464D',
  FOUNDATION_TERCIARY_100: '#172540',
  FOUNDATION_BACKGROUND_80: '#CDCCCC',
  FOUNDATION_BACKGROUND_60: '#FAFAFA',
  FOUNDATION_BACKGROUND_70: '#F2F2F2'
};
