import { Text, View } from "@gluestack-ui/themed";
import { FC } from "react";
import { IBlockedUserPageProps } from "../routes/Routes";

export const BlockedUserPage: FC<IBlockedUserPageProps> = (props: IBlockedUserPageProps) => {
  return (
    <View>
      <Text>Blocked User</Text>
    </View>
  );
}