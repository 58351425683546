import { View, Input, InputField, SearchIcon, InputIcon, InputSlot, Select, SelectItem, ChevronDownIcon, Icon, SelectBackdrop, SelectContent, SelectDragIndicator, SelectDragIndicatorWrapper, SelectIcon, SelectInput, SelectPortal, SelectTrigger } from "@gluestack-ui/themed"
import { AnalysisDTO } from "../../generated/client-api-client"
import { UNCONFIRMED } from "../../pages/AnalysisListPage";
import { useContext } from "react";
import { TranslationContext } from "../../contexts/translations/Translation";

type IAnalysisHistoryFilterBarProps = {
  status: AnalysisDTO.status | "" | typeof UNCONFIRMED;
  setStatus: (status: AnalysisDTO.status | "" | typeof UNCONFIRMED) => void;
  setSearch: (search: string) => void;
  search: string;
  testID?: string;
}

const AnalysisHistoryFilterBar = (props: IAnalysisHistoryFilterBarProps) => {
  const { testID } = props;

  const { status, setStatus, setSearch, search } = props;
  const { i18n } = useContext(TranslationContext);

  return (
    <View
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      borderRadius={10}
      backgroundColor="white"
      padding={10}
      marginBottom={15}
    >
      <Input
        variant="outline"
        size="md"
        isDisabled={false}
        isInvalid={false}
        isReadOnly={false}
        width="70%"
      >
        <InputSlot pl={30}>
          <InputIcon as={SearchIcon} />
        </InputSlot>
        <InputField placeholder={i18n?.t("search")} value={search} onChangeText={setSearch} testID={`${testID ?? ''}${AnalysisHistoryFilterBar.name}_searchBar`} />
      </Input>

      <Select width="25%" onValueChange={setStatus} selectedValue={status} testID={`${testID ?? ''}${AnalysisHistoryFilterBar.name}_select`}>
        <SelectTrigger variant="outline" size="md" >
          <SelectInput placeholder={i18n?.t('status.status')} />
          <SelectIcon>
            <Icon as={ChevronDownIcon} />
          </SelectIcon>
        </SelectTrigger>
        <SelectPortal>
          <SelectContent>
            <SelectItem label={i18n?.t('status.all')} value="" />
            <SelectItem label={i18n?.t('status.success')} value={AnalysisDTO.status.COMPLETED} />
            <SelectItem label={i18n?.t('status.confirmed')} value={AnalysisDTO.status.CONFIRMED} />
            <SelectItem label={i18n?.t('status.unconfirmed')} value={UNCONFIRMED} />
            <SelectItem label={i18n?.t('status.error')} value={AnalysisDTO.status.ERROR} />
            <SelectItem label={i18n?.t('status.idle')} value={AnalysisDTO.status.IDLE} />
            <SelectItem label={i18n?.t('status.processing')} value={AnalysisDTO.status.PROCESSING} />
          </SelectContent>
        </SelectPortal>
      </Select>

    </View>)
}

export default AnalysisHistoryFilterBar