/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { WorkspaceDTO } from './WorkspaceDTO';

export type ScanDistributionPerWorkspaceDTO = {
    id: string;
    consumed: number;
    strategy: ScanDistributionPerWorkspaceDTO.strategy;
    total: number;
    workspace: WorkspaceDTO;
};

export namespace ScanDistributionPerWorkspaceDTO {

    export enum strategy {
        ON_DEMAND = 'ON_DEMAND',
        PER_USER = 'PER_USER',
        POOL = 'POOL',
    }


}

