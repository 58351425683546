/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkCreateMediaDTO } from '../models/BulkCreateMediaDTO';
import type { CreatedMediaDTO } from '../models/CreatedMediaDTO';
import type { CreateMediaDTO } from '../models/CreateMediaDTO';
import type { MediaDTO } from '../models/MediaDTO';
import type { MediaListDTO } from '../models/MediaListDTO';
import type { UpdateMediaDTO } from '../models/UpdateMediaDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MediaService {

    /**
     * Create
     * @param requestBody CreateMediaDTO
     * @returns any Successful response
     * @returns CreatedMediaDTO
     * @throws ApiError
     */
    public static mediaControllerCreate(
        requestBody?: CreateMediaDTO,
    ): CancelablePromise<any | CreatedMediaDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/medias',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @param archived
     * @param deviceType
     * @param microscopeType
     * @param pageNumber
     * @param pageSize
     * @param reactants
     * @param status
     * @param strainId
     * @param title
     * @param zoom
     * @returns MediaListDTO
     * @throws ApiError
     */
    public static mediaControllerSearch(
        archived?: boolean,
        deviceType?: string,
        microscopeType?: string,
        pageNumber?: number,
        pageSize?: number,
        reactants?: string,
        status?: 'APPROVED' | 'PENDING' | 'REJECTED',
        strainId?: string,
        title?: string,
        zoom?: number,
    ): CancelablePromise<MediaListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/medias',
            query: {
                'archived': archived,
                'deviceType': deviceType,
                'microscopeType': microscopeType,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'reactants': reactants,
                'status': status,
                'strainId': strainId,
                'title': title,
                'zoom': zoom,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static mediaControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/medias/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Read one
     * @param id
     * @returns MediaDTO
     * @throws ApiError
     */
    public static mediaControllerReadOne(
        id: string,
    ): CancelablePromise<MediaDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/medias/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update
     * @param id
     * @param requestBody UpdateMediaDTO
     * @returns MediaDTO
     * @throws ApiError
     */
    public static mediaControllerUpdate(
        id: string,
        requestBody?: UpdateMediaDTO,
    ): CancelablePromise<MediaDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/medias/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Bulk create
     * @param requestBody
     * @returns any Successful response
     * @returns BulkCreateMediaDTO
     * @throws ApiError
     */
    public static mediaControllerBulkCreate(
        requestBody?: Array<Record<string, any>>,
    ): CancelablePromise<any | Array<BulkCreateMediaDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/medias/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
