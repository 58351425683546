import { Modal, Heading, ModalBackdrop, ModalContent, ModalHeader, ModalCloseButton, Icon, CloseIcon, ModalBody, Input, InputField, Text } from "@gluestack-ui/themed";
import { Pressable } from "react-native";
import { COLORS } from "../../theme/colors";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext } from "react";
import { TranslationContext } from "../../contexts/translations/Translation";
import { Auth } from "../../contexts/session/Auth";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { I18n } from "i18n-js";

type IForgotPasswordModalProps = {
    setShowModal: (showModal: boolean)=>void;
    auth: Auth | undefined;
    setFeedback: (feedback: string)=>void;
    i18n: I18n | undefined;
    testID?: string;
}

const ForgotPasswordModal = (props: IForgotPasswordModalProps) =>{

  const {i18n} = props;

  const validationSchema = Yup.object().shape({
      forgotPasswordEmail: Yup.string()
        .email(i18n?.t('page.login.invalid_email'))
        .required(i18n?.t('page.login.required_email')),
    });
          
  const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        forgotPasswordEmail: "",
      },
      validateOnChange: false,
      validationSchema: validationSchema,
      onSubmit: async (values) => {      
          await props.auth?.requestChangePassword(values.forgotPasswordEmail);
          props.setShowModal(false);
          props.setFeedback(`${i18n?.t('page.login.reset_password')} ${values.forgotPasswordEmail}`);
      },
    });
    
    return (<>
          <ModalHeader>
            <Heading size="lg" testID={`${props.testID ?? ''}${ForgotPasswordModal.name}_title`}>{i18n?.t('page.login.request_change_password')}</Heading>
            <ModalCloseButton>
              <Icon as={CloseIcon} />
            </ModalCloseButton>
          </ModalHeader>
          <ModalBody>
            <Input marginTop={20} marginBottom={5}>
              <InputField
                testID={`${props.testID ?? ''}${ForgotPasswordModal.name}_emailInput`}
                onChangeText={formik.handleChange('forgotPasswordEmail')}
                onBlur={formik.handleBlur('forgotPasswordEmail')}
                value={formik.values.forgotPasswordEmail}
                placeholder={i18n?.t('page.login.email')}
                keyboardType="email-address"
                autoCapitalize="none"
                onFocus={() => {
                  formik.setErrors({});
                }}
              />
            </Input>
            {formik.errors.forgotPasswordEmail && (
              <Text testID={`${props.testID ?? ''}${ForgotPasswordModal.name}_emailInputError`} color={COLORS.STATUS.WARNING_2}>{formik.errors.forgotPasswordEmail}</Text>
            )}
            <PrimaryButton
              testID={`${props.testID ?? ''}${ForgotPasswordModal.name}_requestChangeButton`}
              style={{marginTop: 35, alignSelf: 'center'}}
              isLoading={props.auth?.isLoading}
              content={i18n?.t('page.login.request_change_password')}
              onPress={async ()=> { formik.handleSubmit() }}
            /> 
          </ModalBody>
      </>)
}

export default ForgotPasswordModal;