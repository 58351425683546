import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';

import type { AvailableScanDTO, MeUserDTO, WorkspaceDTO, UpdateUserDTO } from '../generated/client-api-client';
import { MeService, ScanService, UserService } from '../generated/client-api-client';
import { AuthContext } from './session/Auth';
import { UserContext } from './User';
import { Modal } from '@gluestack-ui/themed';
import { UserUpdateModal } from '../components/user/UserUpdateModal';
import { TranslationContext } from './translations/Translation';

export interface IUserContainerProps {
  children: any;
}

export const UserContainer = ({ children }: IUserContainerProps) => {
  const auth = useContext(AuthContext);
  const { i18n } = useContext(TranslationContext);

  const [workspaceList, setWorkspaceList] = useState<WorkspaceDTO[] | null>(null);
  const [currentWorkspace, _setCurrentWorkspace] = useState<WorkspaceDTO | null>(null);

  const setWorkspaceInfo = async (workspaces: WorkspaceDTO[]) => {
    setWorkspaceList(workspaces);
    const workspaceId = await AsyncStorage.getItem('workspaceId');
    _setCurrentWorkspace(workspaces.find((workspace) => workspace.id === workspaceId) ?? workspaces[0]);
    await AsyncStorage.setItem('workspaceId', workspaceId ?? workspaces[0].id);
  };

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery<MeUserDTO, any, any>({
    queryKey: ['userMe', auth],
    queryFn: async () => {
      try {
        const meUser = await MeService.meControllerMe();

        if (meUser.userMetadata.isBackOfficeUser) {
          await setWorkspaceInfo(meUser.workspaceList);
          return meUser;
        }
        // When an admin removes a user from a workspace, the user is still in the workspaceList because the token has not been refreshed
        const workspaceToDisplay = meUser.workspaceList.filter((w) => meUser.userMetadata[w.id]);
        if (!workspaceToDisplay.length) {
          auth?.logOut();
          auth?.setIsBlocked(true);
        }
        await setWorkspaceInfo(workspaceToDisplay);
        return {
          ...meUser,
          workspaceList: workspaceToDisplay,
        };
      } catch (error) {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          auth?.logOut();
          auth?.setIsBlocked(true);
        }
        throw error;
      }
    },
  });

  const { data: scanCountData, isLoading: isLoadingScans } = useQuery<AvailableScanDTO | undefined>({
    queryKey: ['scanCountForUser', currentWorkspace],
    queryFn: async () => {
      if (!currentWorkspace) {
        return undefined;
      }
      return ScanService.scanControllerCountAvailableScanTokensForUser(currentWorkspace.id);
    },
    refetchInterval: 3_000,
    refetchOnWindowFocus: true,
  });

  const setCurrentWorkspace = async (id: string) => {
    const workspace = workspaceList?.find((workspaceOfList: WorkspaceDTO) => workspaceOfList.id === id);
    await AsyncStorage.setItem('workspaceId', id);
    _setCurrentWorkspace(workspace!);
  };

  return (
    <>
      <UserContext.Provider
        value={{
          currentWorkspace,
          isError,
          isLoading,
          setCurrentWorkspace,
          user,
          workspaceList,
          scansInformation: scanCountData,
          isScansInformationLoading: isLoadingScans,
        }}
        >
        {children}
      </UserContext.Provider>
    </>
  );
};
