import { FormControl, HStack, VStack, Input, InputField, FormControlError, FormControlErrorText, Text } from "@gluestack-ui/themed";
import { FC, ReactNode } from "react";

export interface IFormItemProps {
  label: string;
  propertyName: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  errors?: string | undefined;
  form: any;
}

export const FormItem: FC<IFormItemProps & {children: ReactNode}> = ({ children, form, label, isRequired, isDisabled, propertyName }: IFormItemProps & {children: any}) => (
  <FormControl isRequired={isRequired} isInvalid={Boolean(form.getFieldMeta(propertyName).error)} key={propertyName} isDisabled={isDisabled}>
    <HStack>
      <FormControl.Label style={{ flex: 1, marginVertical: 10 }} alignSelf="center">
      <Text style={{ flex: 1, marginVertical: 10 }} alignSelf="center">
        {label}
      </Text>
      </FormControl.Label>
      <VStack style={{ flex: 3, margin: 10 }} >
        {children}
        <FormControlError>
          <FormControlErrorText>{form.getFieldMeta(propertyName).error}</FormControlErrorText>
        </FormControlError>
      </VStack>
    </HStack>
  </FormControl>
);