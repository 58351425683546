/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableScanDTO } from '../models/AvailableScanDTO';
import type { DistributeScanDTO } from '../models/DistributeScanDTO';
import type { ScanTokenListDTO } from '../models/ScanTokenListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScanService {

    /**
     * List scans token
     * @param workspaceId
     * @param pageNumber
     * @param pageSize
     * @returns ScanTokenListDTO
     * @throws ApiError
     */
    public static scanControllerListScansToken(
        workspaceId: string,
        pageNumber?: number,
        pageSize?: number,
    ): CancelablePromise<ScanTokenListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/scans',
            path: {
                'workspaceId': workspaceId,
            },
            query: {
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Assign to user
     * @param workspaceId
     * @param requestBody DistributeScanDTO
     * @returns any Successful response
     * @throws ApiError
     */
    public static scanControllerAssignToUser(
        workspaceId: string,
        requestBody?: DistributeScanDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/scans/assign',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Count available scan tokens for workspace
     * @param workspaceId
     * @returns AvailableScanDTO
     * @throws ApiError
     */
    public static scanControllerCountAvailableScanTokensForWorkspace(
        workspaceId: string,
    ): CancelablePromise<AvailableScanDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/scans/workspace',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Count available scan tokens for user
     * @param workspaceId
     * @returns AvailableScanDTO
     * @throws ApiError
     */
    public static scanControllerCountAvailableScanTokensForUser(
        workspaceId: string,
    ): CancelablePromise<AvailableScanDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/scans/user',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Unassign from user
     * @param workspaceId
     * @param requestBody DistributeScanDTO
     * @returns any Successful response
     * @throws ApiError
     */
    public static scanControllerUnassignFromUser(
        workspaceId: string,
        requestBody?: DistributeScanDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/scans/unassign',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
