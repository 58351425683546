import { createContext } from "react";

export interface Auth {
  token?: string;
  isBlocked: boolean;
  isError: boolean;
  isLogged: boolean;
  isLoading: boolean;
  logIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  refetch: () => Promise<void>;
  setIsBlocked: (isBlocked: boolean) => void;
  requestChangePassword: (email: string) =>Promise<void>;
}

export const AuthContext = createContext<Auth | undefined>(undefined);
