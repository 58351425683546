/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DistributeScanToWorkspaceDTO } from '../models/DistributeScanToWorkspaceDTO';
import type { UpdateScanDistributionPerWorkspaceStrategyDTO } from '../models/UpdateScanDistributionPerWorkspaceStrategyDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackOfficeScanService {

    /**
     * Assign to workspace
     * @param workspaceId
     * @param requestBody DistributeScanToWorkspaceDTO
     * @returns any Successful response
     * @throws ApiError
     */
    public static backOfficeScanControllerAssignToWorkspace(
        workspaceId: string,
        requestBody?: DistributeScanToWorkspaceDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/workspaces/{workspaceId}/scans/assign',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update strategy
     * @param workspaceId
     * @param requestBody UpdateScanDistributionPerWorkspaceStrategyDTO
     * @returns any Successful response
     * @throws ApiError
     */
    public static backOfficeScanControllerUpdateStrategy(
        workspaceId: string,
        requestBody?: UpdateScanDistributionPerWorkspaceStrategyDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/back-office/workspaces/{workspaceId}/scans/strategy',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
