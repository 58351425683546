/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisDTO } from '../models/AnalysisDTO';
import type { AnalysisListDTO } from '../models/AnalysisListDTO';
import type { AnalysisQueryExportDTO } from '../models/AnalysisQueryExportDTO';
import type { ConfirmAnalysisResultDTO } from '../models/ConfirmAnalysisResultDTO';
import type { CreateAnalysisDTO } from '../models/CreateAnalysisDTO';
import type { CreatedAnalysisDTO } from '../models/CreatedAnalysisDTO';
import type { ExportResponseDTO } from '../models/ExportResponseDTO';
import type { UpdateAnalysisDTO } from '../models/UpdateAnalysisDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalysisService {

    /**
     * Confirm result analysis
     * @param workspaceId
     * @param id
     * @param requestBody ConfirmAnalysisResultDTO
     * @returns AnalysisDTO
     * @throws ApiError
     */
    public static analysisControllerConfirmResultAnalysis(
        workspaceId: string,
        id: string,
        requestBody?: ConfirmAnalysisResultDTO,
    ): CancelablePromise<AnalysisDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/analysis/{id}/confirm-analysis',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create
     * @param workspaceId
     * @param requestBody CreateAnalysisDTO
     * @returns CreatedAnalysisDTO
     * @throws ApiError
     */
    public static analysisControllerCreate(
        workspaceId: string,
        requestBody?: CreateAnalysisDTO,
    ): CancelablePromise<CreatedAnalysisDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/analysis',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @param workspaceId
     * @param code
     * @param confirmedResult
     * @param createdAtDateStart
     * @param createdAtDateEnd
     * @param name
     * @param multipleStatus
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortOrder
     * @param status
     * @param userId
     * @param userEmail
     * @param userFirstName
     * @param userLastName
     * @param typeExport
     * @returns AnalysisListDTO
     * @throws ApiError
     */
    public static analysisControllerSearch(
        workspaceId: string,
        code?: string,
        confirmedResult?: string,
        createdAtDateStart?: string,
        createdAtDateEnd?: string,
        name?: string,
        multipleStatus?: Array<'IDLE' | 'PROCESSING' | 'COMPLETED' | 'ERROR' | 'CONFIRMED'>,
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortOrder?: 'asc' | 'desc',
        status?: 'IDLE' | 'PROCESSING' | 'COMPLETED' | 'ERROR' | 'CONFIRMED',
        userId?: string,
        userEmail?: string,
        userFirstName?: string,
        userLastName?: string,
        typeExport?: 'csv' | 'pdf',
    ): CancelablePromise<AnalysisListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/analysis',
            path: {
                'workspaceId': workspaceId,
            },
            query: {
                'code': code,
                'confirmedResult': confirmedResult,
                'createdAtDateStart': createdAtDateStart,
                'createdAtDateEnd': createdAtDateEnd,
                'name': name,
                'multipleStatus': multipleStatus,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'status': status,
                'userId': userId,
                'userEmail': userEmail,
                'userFirstName': userFirstName,
                'userLastName': userLastName,
                'typeExport': typeExport,
            },
        });
    }

    /**
     * Delete
     * @param workspaceId
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static analysisControllerDelete(
        workspaceId: string,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/workspaces/{workspaceId}/analysis/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

    /**
     * Read one
     * @param workspaceId
     * @param id
     * @returns AnalysisDTO
     * @throws ApiError
     */
    public static analysisControllerReadOne(
        workspaceId: string,
        id: string,
    ): CancelablePromise<AnalysisDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/analysis/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

    /**
     * Update
     * @param workspaceId
     * @param id
     * @param requestBody UpdateAnalysisDTO
     * @returns AnalysisDTO
     * @throws ApiError
     */
    public static analysisControllerUpdate(
        workspaceId: string,
        id: string,
        requestBody?: UpdateAnalysisDTO,
    ): CancelablePromise<AnalysisDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/workspaces/{workspaceId}/analysis/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Export
     * @param workspaceId
     * @param requestBody AnalysisQueryExportDTO
     * @returns ExportResponseDTO
     * @throws ApiError
     */
    public static analysisControllerExport(
        workspaceId: string,
        requestBody?: AnalysisQueryExportDTO,
    ): CancelablePromise<ExportResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/analysis/export',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Start analysis
     * @param workspaceId
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static analysisControllerStartAnalysis(
        workspaceId: string,
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/analysis/{id}/start-analysis',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

}
