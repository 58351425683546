/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserDTO = {
    email?: string;
    firstName?: string;
    lastName?: string;
    role?: UpdateUserDTO.role;
    status?: UpdateUserDTO.status;
    zohoId?: string;
    phoneNumber?: string;
};

export namespace UpdateUserDTO {

    export enum role {
        CLIENT_USER = 'client-user',
        ADMIN_USER = 'admin-user',
        BACKOFFICE_USER = 'backoffice-user',
    }

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }


}

