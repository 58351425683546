import { Button, ButtonIcon, ButtonSpinner, ButtonText } from '@gluestack-ui/themed';
import { FC } from 'react';
import { PressableStateCallbackType, StyleProp, ViewStyle } from 'react-native/types';
import { COLORS } from '../../theme/colors';

export interface ITerciaryButtonProps {
    onPress: () => Promise<void>;
    content?: string;
    isLoading?: boolean;
    isDisable?: boolean;
    width?: string | number;
    minWidth?: string | number;
    style?: StyleProp<ViewStyle> | ((state: PressableStateCallbackType) => StyleProp<ViewStyle>);
    leftIcon?: FC;
    testID?: string;
}
export const TerciaryButton: FC<ITerciaryButtonProps> = (props: ITerciaryButtonProps) => {
    return (
        <Button
            testID={props?.testID}
            bg='transparent'
            onPress={async () => props.onPress()}
            isDisabled={props.isLoading === true || props.isDisable === true}
            width='fit-content'
            minWidth='fit-content'
            rounded="$lg"
            style={props?.style}
        >
            {
                props.isLoading && (<ButtonSpinner mr="$1" testID={`${props?.testID && ""}spinner`}/>)
            }
            {
                !props.isLoading && props.leftIcon && (
                    <ButtonIcon as={() => <props.leftIcon />} />
                )
            }
            <ButtonText color={COLORS.ACCENT[100]} >
                {props.content}
            </ButtonText>
        </Button>
    )
}