/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ColonyColor {
    BLUE_GREEN = 'BLUE_GREEN',
    BEIGE_CREAM_YELLOW = 'BEIGE_CREAM_YELLOW',
    WHITE_GREY = 'WHITE_GREY',
    UNKNOWN = 'UNKNOWN',
}
