/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type StrainQueryParams = {
    commonName?: string;
    classification?: string;
    pageNumber?: number;
    pageSize?: number;
    scientificName?: string;
    status?: StrainQueryParams.status;
    taxonomy?: string;
};

export namespace StrainQueryParams {

    export enum status {
        APPROVED = 'APPROVED',
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
    }


}

