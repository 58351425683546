/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportResponseDTO = {
    status: ExportResponseDTO.status;
};

export namespace ExportResponseDTO {

    export enum status {
        OK = 'OK',
        ERROR = 'ERROR',
    }


}

