/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateMediaDTO = {
    archived?: boolean;
    imageUrl?: string;
    microscopeType?: string;
    strainId?: string;
    zoom?: number;
    title?: string;
    reactants?: string;
    status?: UpdateMediaDTO.status;
    deviceType?: string;
    metadata?: Record<string, any>;
};

export namespace UpdateMediaDTO {

    export enum status {
        APPROVED = 'APPROVED',
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
    }


}

