/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableScanDetailsDTO } from '../models/AvailableScanDetailsDTO';
import type { CreateWorkspaceDTO } from '../models/CreateWorkspaceDTO';
import type { UpdateWorkspaceDTO } from '../models/UpdateWorkspaceDTO';
import type { WorkspaceDTO } from '../models/WorkspaceDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkspaceService {

    /**
     * Archive
     * @param workspaceId
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static workspaceControllerArchive(
        workspaceId: string,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/archive',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Create
     * @param requestBody CreateWorkspaceDTO
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static workspaceControllerCreate(
        requestBody?: CreateWorkspaceDTO,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static workspaceControllerSearch(): CancelablePromise<Array<WorkspaceDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces',
        });
    }

    /**
     * Delete
     * @param workspaceId
     * @returns any Successful response
     * @throws ApiError
     */
    public static workspaceControllerDelete(
        workspaceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/workspaces/{workspaceId}',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Read one
     * @param workspaceId
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static workspaceControllerReadOne(
        workspaceId: string,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Update
     * @param workspaceId
     * @param requestBody UpdateWorkspaceDTO
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static workspaceControllerUpdate(
        workspaceId: string,
        requestBody?: UpdateWorkspaceDTO,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/workspaces/{workspaceId}',
            path: {
                'workspaceId': workspaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Unarchive
     * @param workspaceId
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static workspaceControllerUnarchive(
        workspaceId: string,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/workspaces/{workspaceId}/unarchive',
            path: {
                'workspaceId': workspaceId,
            },
        });
    }

    /**
     * Users available scans
     * @param workspaceId
     * @param usersId
     * @returns AvailableScanDetailsDTO
     * @throws ApiError
     */
    public static workspaceControllerUsersAvailableScans(
        workspaceId: string,
        usersId?: Array<Record<string, any>>,
    ): CancelablePromise<AvailableScanDetailsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/workspaces/{workspaceId}/users/available-scans',
            path: {
                'workspaceId': workspaceId,
            },
            query: {
                'usersId': usersId,
            },
        });
    }

}
