/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateMediaDTO = {
    imageUrl?: string;
    imageFileName: string;
    microscopeType: string;
    strainId: string;
    zoom: number;
    title?: string;
    reactants?: string;
    deviceType: string;
    metadata?: Record<string, any>;
    archived?: boolean;
    status?: CreateMediaDTO.status;
};

export namespace CreateMediaDTO {

    export enum status {
        APPROVED = 'APPROVED',
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
    }


}

