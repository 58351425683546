import { config } from '@gluestack-ui/config';
import { GluestackUIProvider } from '@gluestack-ui/themed';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MediaContainer } from './contexts/media/MediaContainer';

import { Header } from './components/nav-bar/Header';
import { OpenAPI as ClientOpenAPIConfiguration } from './generated/client-api-client/core/OpenAPI';
import { AnalysisDetails } from './pages/AnalysisDetailsPage';
import { AnalysisExportPage } from './pages/AnalysisExportPage';
import { EditAnalysisFormPage } from './pages/AnalysisFormPage';
import { AnalysisListPage } from './pages/AnalysisListPage';
import { CameraPage } from './pages/CameraPage';
import { PathogenDetailPage } from './pages/PathogenDetailPage';
import { Routes } from './routes/Routes';
import { CameraPageWithStream } from './pages/CameraPageWithStream';
import { LogInPage } from './pages/LogInPage';
import { AuthContainer } from './contexts/session/AuthContainer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ApiRequestOptions } from './generated/client-api-client/core/ApiRequestOptions';
import { env } from './utils/Env';
import React from 'react';
import { BlockedUserPage } from './pages/BlockedUserPage';
import { UserContainer } from './contexts/UserContainer';
import { TranslationContainer } from './contexts/translations/TranslationContext';
import './styles.css';

const Stack = createNativeStackNavigator();

ClientOpenAPIConfiguration.BASE = env.apiBaseUrl;
ClientOpenAPIConfiguration.TOKEN = async (options: ApiRequestOptions) => {
  if (!options.url?.includes('auth')) {
    const expiration = await AsyncStorage.getItem('expiration');
    if (expiration && new Date() > new Date(expiration)) {
      await AsyncStorage.setItem('token', '');
      return '';
    } else {
      const token = await AsyncStorage.getItem('token');
      return token ?? '';
    }
  }
  return '';
}
export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
      },
    },
  });

  return (
    <NavigationContainer>
      <GluestackUIProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <TranslationContainer>
            <AuthContainer>

              <AuthContainer.NotAuthorized>
                <Stack.Navigator id='not-authorized' initialRouteName={Routes.LOGIN}>
                  <Stack.Screen
                    name={Routes.LOGIN}
                    component={LogInPage}
                    options={{ header: () => null }}
                  />
                </Stack.Navigator>
              </AuthContainer.NotAuthorized>

              <AuthContainer.Authorized>
                  <UserContainer>
                    <MediaContainer>
                      <Stack.Navigator id='authorized' initialRouteName={Routes.ANALYSIS_LIST}>
                        <Stack.Group screenOptions={
                          ({ route }) => ({
                            header: () => {
                              return (<Header route={route} />);
                            },
                          })
                        }>
                          <Stack.Screen
                            name={Routes.ANALYSIS}
                            component={AnalysisDetails}
                          />
                          <Stack.Screen
                            name={Routes.ANALYSIS_EXPORT}
                            component={AnalysisExportPage}
                          />
                          <Stack.Screen
                            name={Routes.ANALYSIS_FORM}
                            component={EditAnalysisFormPage}
                          />
                          <Stack.Screen
                            name={Routes.ANALYSIS_LIST}
                            component={AnalysisListPage}
                            initialParams={{ refresh: false }}
                          />

                          <Stack.Screen
                            name={Routes.CAMERA}
                            component={CameraPage}
                          />
                          <Stack.Screen
                            name={Routes.CAMERA_WITH_STREAM}
                            component={CameraPageWithStream}
                          />

                          <Stack.Screen
                            name={Routes.PATHOGEN_INFO}
                            component={PathogenDetailPage}
                          />
                        </Stack.Group>
                      </Stack.Navigator>
                    </MediaContainer>
                  </UserContainer>
              </AuthContainer.Authorized>

              <AuthContainer.Blocked>
                <Stack.Navigator id='blocked' initialRouteName={Routes.BLOCKED_USER}>
                  <Stack.Screen
                    name={Routes.BLOCKED_USER}
                    component={BlockedUserPage}
                    options={{ header: () => null }}
                  />
                </Stack.Navigator>
              </AuthContainer.Blocked>
              
            </AuthContainer>
          </TranslationContainer>
        </QueryClientProvider>
      </GluestackUIProvider>
    </NavigationContainer>
  );
}
