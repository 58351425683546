import { Center, Heading } from "@gluestack-ui/themed";
import { FC } from "react";

export interface ITitleProps {
    props?: any;
    title: string;
    size?: 'xl' | 'lg' | '5xl' | '4xl' | '3xl' | '2xl' | 'md' | 'sm' | 'xs'
}

export const Title: FC<ITitleProps> = (props: ITitleProps) => {
    return (
        <Heading {...props.props} size={props.size ?? "xl"}>{props.title}</Heading>
    );
}