import { StyleSheet, View } from 'react-native';

export interface IProgressBarProps {
  progress: number;
  testID?: string;
}

export function ProgressBar(props: IProgressBarProps) {
  const { progress, testID } = props;

  const progressStyle = StyleSheet.create({
    progress: {
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: progress > 0.8 ? 'green' : progress > 0.6 ? 'orange' : 'red',
      borderRadius: 8,
      height: 6,
      width: `${progress * 100}%`,
    },
  });

  return (
    <View testID={`${testID ?? ''}${ProgressBar.name}_bar`} style={styles.progressBar}>
      <View testID={`${testID ?? ''}${ProgressBar.name}_progress`} style={progressStyle.progress} />
    </View>
  );
}

const styles = StyleSheet.create({
  progressBar: {
    width: '100%',
    height: 10,
    backgroundColor: '#fff',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#555',
    flexDirection: 'row',
  },
});
