import { FC, useContext, useState } from 'react';
import { Box, View } from '@gluestack-ui/themed';
import { TouchableOpacity } from 'react-native';
import { MediaContext } from '../../contexts/media/Media';

export interface IChronoCamera {
  setPhotoUriList: (photoUriList: string[]) => void;
}

export const CameraWithStream: FC<IChronoCamera> = (props: IChronoCamera) => {
  const { setPhotoUriList } = props;
  const { takePhoto } = useContext(MediaContext);

  return (
    <Box
      display='flex'
      flexDirection='column'
      flex={1}
      width='100%'
      height='100%'
      alignItems='center'
      overflow='hidden'
      gap={20}
      borderRadius={20}>
      <video id='video' style={{ flex: 1, width: '100%' }} autoPlay onClick={() => takePhoto(setPhotoUriList)}>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            flexDirection: 'row',
            flex: 1,
            width: '100%',
            padding: 20,
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              alignSelf: 'center',
              flex: 1,
              alignItems: 'center',
            }}
          >
            <TouchableOpacity
              onPress={() => takePhoto(setPhotoUriList)}
              style={{
                width: 70,
                height: 70,
                bottom: 0,
                borderRadius: 50,
                backgroundColor: '#fff',
              }}
            />
          </View>
        </View>
      </video>
    </Box>
  )
}