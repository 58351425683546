import { FormControl, HStack, Select, SelectTrigger, SelectInput, SelectIcon, Icon, SelectPortal, SelectContent, SelectItem } from "@gluestack-ui/themed";
import { ChevronDownIcon } from "lucide-react-native";
import { FC } from "react";

interface IFormItemDropDownOption {
  label: string;
  value: string;
}

export interface IFormItemDropDownProps {
  onChange: (value: string) => void;
  options: IFormItemDropDownOption[];
  placeholder: string;
  value: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

export const FormItemDropDown: FC<IFormItemDropDownProps> = ({ isDisabled, isRequired, onChange, options, placeholder, value }: IFormItemDropDownProps) => {
  return (
    <Select selectedValue={value} onValueChange={onChange} isDisabled={isDisabled} isRequired={isRequired}>
      <SelectTrigger variant="outline" size="md">
        <SelectInput placeholder={placeholder} value={options.find((opt) => opt.value === value)?.label ?? ''}/>
        <SelectIcon>
          <Icon as={ChevronDownIcon} />
        </SelectIcon>
      </SelectTrigger>
      <SelectPortal>
        <SelectContent>
          {options.map((opt, index: number) => (
            <SelectItem key={index} label={opt.label} value={opt.value} />
          ))}
        </SelectContent>
      </SelectPortal>
    </Select>
  );
};