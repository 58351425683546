import { Box, ButtonText, CloseIcon, Divider, Heading, Icon, Input, InputField, Modal, ModalBackdrop, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, View } from '@gluestack-ui/themed';
import { FC, useContext } from 'react';
import { useState } from 'react';
import { Button, Pressable, StyleSheet, TouchableOpacity } from 'react-native';
import { useFormik } from 'formik';
import { COLORS } from '../theme/colors';
import BackgroundSVG from '../assets/background.svg';
import { Image } from '@gluestack-ui/themed';
import { PrimaryButton } from '../components/buttons/PrimaryButton';
import Bioeureka from '../assets/bioeureka-black.svg';
import { AuthContext } from '../contexts/session/Auth';
import * as Yup from 'yup';
import { TranslationContext } from '../contexts/translations/Translation';
import ForgotPasswordModal from '../components/login/ForgotPasswordModal';


export const LogInPage: FC<ILoginPageProps> = (_: ILoginPageProps) => {
  const { testID } = _;
  const { i18n } = useContext(TranslationContext);

  const auth = useContext(AuthContext);
  const [credentialsError, setCredentialsError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [feedback, setFeedback] = useState("")

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n?.t('page.login.invalid_email'))
      .required(i18n?.t('page.login.required_email')),
    password: Yup.string()
      .min(8, i18n?.t('page.login.invalid_password'))
      .required(i18n?.t('page.login.required_password'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setCredentialsError(false);
      try {
        await auth?.logIn(values.email, values.password);
      } catch (error) {
        setCredentialsError(true)
      }
    },
  });

  return (
    <Box style={styles.layout}>
      <Image testID={`${testID ?? ''}${LogInPage.name}_backgroundImage`} alt="" style={styles.backgroundImage} source={{ uri: BackgroundSVG }} />
      <Box
        backgroundColor={COLORS.NEUTRAL[0]}
        paddingVertical={30}
        paddingHorizontal={50}
        borderRadius={20}
        shadowColor='#171717'
        shadowOffset={{ width: -2, height: 4 }}
        shadowOpacity={0.2}
        shadowRadius={3}
      >
        <Image testID={`${testID ?? ''}${LogInPage.name}_logo`} alt="Logo" source={{ uri: Bioeureka }} height={55} width={400} />
        <Divider marginVertical={20} />

        <Input marginTop={20} marginBottom={5}>
          <InputField
            testID={`${testID ?? ''}${LogInPage.name}_emailInput`}
            onChangeText={formik.handleChange('email')}
            onBlur={formik.handleBlur('email')}
            value={formik.values.email}
            placeholder={i18n?.t('page.login.email')}
            keyboardType="email-address"
            autoCapitalize="none"
            onFocus={() => {
              formik.setErrors({});
            }}
          />
        </Input>
        {formik.errors.email && (
          <Text testID={`${testID ?? ''}${LogInPage.name}_emailInputError`} color={COLORS.STATUS.WARNING_2}>{formik.errors.email}</Text>
        )}

        <Input marginTop={20} marginBottom={5}>
          <InputField
            testID={`${testID ?? ''}${LogInPage.name}_passwordInput`}
            onChangeText={formik.handleChange('password')}
            onBlur={formik.handleBlur('password')}
            value={formik.values.password}
            placeholder={i18n?.t('page.login.password')}
            secureTextEntry
          />
        </Input>
        {formik.errors.password && (
          <Text testID={`${testID ?? ''}${LogInPage.name}_passwordInputError`} marginBottom={10} color={COLORS.STATUS.WARNING_2}>{formik.errors.password}</Text>
        )}
    
        <Pressable onPress={()=>{setShowModal(true)}}>
          <Text color="blue" marginTop={20}>{i18n?.t('page.login.forgot_password')}</Text>
        </Pressable>
        <PrimaryButton
          testID={`${testID ?? ''}${LogInPage.name}_loginButton`}
          style={{marginTop: 35, alignSelf: 'center'}}
          isLoading={auth?.isLoading}
          content={i18n?.t('page.login.login')}
          onPress={async ()=> { formik.handleSubmit() }}/> 
      </Box>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <ModalBackdrop />
        <ModalContent>
          <ForgotPasswordModal setFeedback={setFeedback} setShowModal={setShowModal} auth={auth} i18n={i18n}/>
        </ModalContent>
      </Modal>
      {feedback && <Text color={COLORS.STATUS.SUCCESS} margin={20}>{feedback}</Text>}
      {credentialsError && <Text testID={`${testID ?? ''}${LogInPage.name}_loginErrorText`} color={COLORS.STATUS.WARNING_2} margin={20}>{i18n?.t('page.login.error')}</Text>}
    </Box>
  );
};

const styles = StyleSheet.create({
  layout: {
    backgroundColor: COLORS.FOUNDATION_PRIMARY,
    height: '100%',
    paddingHorizontal: '10%',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backgroundImage: {
    position: "absolute",
    width: 450,
    height: 450,
    top: 0,
    right: -60,
    zIndex: -1,
  }
});
