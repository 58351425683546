import { Image } from '@gluestack-ui/themed';
import type { FC } from 'react';
import Bioeureka from '../../assets/bioeureka.svg';

export const HeaderLeft: FC = () => {
  return (
    <>
      <Image alt="Bioeureka logo" source={Bioeureka} style={{ height: 34, width: 247 }} />
    </>
  );
};
