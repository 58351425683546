import { FC, useContext } from "react";
import { Picker } from '@react-native-picker/picker';
import { Text } from '@gluestack-ui/themed';

import { Media, MediaContext } from "../../contexts/media/Media";
import { TranslationContext } from "../../contexts/translations/Translation";

export interface ICameraPickerProps {
  testID?: string;
}

export const CameraPicker: FC<ICameraPickerProps> = (props: ICameraPickerProps) => {
  const { cameraList, changeCamera, hasCameraStarted, isCameraAllowed, selectedCamera }: Media = useContext(MediaContext);
  const { i18n } = useContext(TranslationContext);
  
  return (
    <>
      {hasCameraStarted && <Picker
        style={{ borderRadius: 10, padding: 10 }}
        selectedValue={selectedCamera}
        onValueChange={(itemValue) => { changeCamera(itemValue as string) }}
      >
        {
          cameraList?.map((camera) => {
            return (
              <Picker.Item key={camera.deviceId} label={camera.label} value={camera.deviceId} />
            )
          })
        }
      </Picker>}
      {
        !isCameraAllowed && (
          <Text>
            {i18n.t('camera.permission-denied')}
          </Text>
        )
      }
    </>
  )
}