/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisHistoryDTO } from '../models/AnalysisHistoryDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackOfficeAnalysisHistoryService {

    /**
     * List history of analysis
     * @param workspaceId
     * @param analysisId
     * @returns AnalysisHistoryDTO
     * @throws ApiError
     */
    public static backOfficeAnalysisHistoryControllerListHistoryOfAnalysis(
        workspaceId: string,
        analysisId: string,
    ): CancelablePromise<Array<AnalysisHistoryDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces/{workspaceId}/analysis/{analysisId}/history',
            path: {
                'workspaceId': workspaceId,
                'analysisId': analysisId,
            },
        });
    }

}
