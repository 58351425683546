import { Button, ButtonIcon, ButtonSpinner, ButtonText } from '@gluestack-ui/themed';
import { FC } from 'react';
import { PressableStateCallbackType, StyleProp, ViewStyle } from 'react-native/types';
import { COLORS } from '../../theme/colors';

export interface IPrimaryButtonProps {
    onPress: () => Promise<void>;
    content?: string;
    isLoading?: boolean;
    isDisable?: boolean;
    width?: string | number;
    minWidth?: string | number;
    style?: StyleProp<ViewStyle> | ((state: PressableStateCallbackType) => StyleProp<ViewStyle>);
    leftIcon?: FC;
    testID?: string;
}
export const PrimaryButton: FC<IPrimaryButtonProps> = (props: IPrimaryButtonProps) => {
    return (
        <Button
            testID={props?.testID}
            bg={COLORS.ACCENT[100]}
            $hover-bg={COLORS.ACCENT[110]}
            onPress={async () => props.onPress()}
            isDisabled={props.isLoading === true || props.isDisable === true}
            width={props.width ?? 'fit-content'}
            minWidth={props.minWidth ?? 250}
            rounded="$lg"
            style={props?.style}
        >
            {
                props.isLoading && (<ButtonSpinner mr="$1" testID={`${props?.testID && ""}spinner`}/>)
            }
            {
                !props.isLoading && props.leftIcon && (
                    <ButtonIcon as={() => <props.leftIcon/>}/>
                )
            }
            <ButtonText
                style={{ color: COLORS.NEUTRAL[0] }}>
                {props.content}
            </ButtonText>
        </Button>
    )
}