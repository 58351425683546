/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateScanDistributionPerWorkspaceStrategyDTO = {
    strategy: UpdateScanDistributionPerWorkspaceStrategyDTO.strategy;
};

export namespace UpdateScanDistributionPerWorkspaceStrategyDTO {

    export enum strategy {
        ON_DEMAND = 'ON_DEMAND',
        PER_USER = 'PER_USER',
        POOL = 'POOL',
    }


}

