import { FC, useState } from 'react';
import { Box, Image, Text, View } from '@gluestack-ui/themed';
import { Camera } from 'expo-camera';
import { TouchableOpacity } from 'react-native';
export interface IChronoCamera {
    onPhotoTaken: (image: { uri: string }) => Promise<void>;
    camera?: Camera | null | undefined;
}
export const ChronoCamera: FC<IChronoCamera> = (props: IChronoCamera) => {
    const [camera, setCamera] = useState<Camera | undefined | null>(props.camera);

    const takePicture = async () => {
        // if (!camera) return;
        const photo = await camera?.takePictureAsync();
        if (photo) {
            props?.onPhotoTaken({ uri: photo.uri })
            camera?.pausePreview();
        }
    };

    return (
        <Box
            display='flex'
            flexDirection='column'
            flex={1}
            width='100%'
            height='100%'
            alignItems='center'
            overflow='hidden'
            gap={20}
            borderRadius={20}>
            <Camera
                style={{ flex: 1, width: '100%', height: '100%' }}
                ref={(cameraReference) => {
                    setCamera(cameraReference);
                }}
            >
                <View
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        flexDirection: 'row',
                        flex: 1,
                        width: '100%',
                        padding: 20,
                        justifyContent: 'space-between',
                    }}
                >
                    <View
                        style={{
                            alignSelf: 'center',
                            flex: 1,
                            alignItems: 'center',
                        }}
                    >
                        <TouchableOpacity
                            onPress={takePicture}
                            style={{
                                width: 70,
                                height: 70,
                                bottom: 0,
                                borderRadius: 50,
                                backgroundColor: '#fff',
                            }}
                        />
                    </View>
                </View>
            </Camera>
        </Box>
    )
}