/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisReportDTO } from '../models/AnalysisReportDTO';
import type { AnalysisReportListDTO } from '../models/AnalysisReportListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackOfficeAnalysisReportService {

    /**
     * Search
     * @param workspaceId
     * @param createdAtDateBegin
     * @param createdAtDateEnd
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortOrder
     * @param status
     * @param userId
     * @returns AnalysisReportListDTO
     * @throws ApiError
     */
    public static backOfficeAnalysisReportControllerSearch(
        workspaceId: string,
        createdAtDateBegin?: string,
        createdAtDateEnd?: string,
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortOrder?: 'asc' | 'desc',
        status?: 'IDLE' | 'PROCESSING' | 'COMPLETED' | 'ERROR',
        userId?: string,
    ): CancelablePromise<AnalysisReportListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces/{workspaceId}/analysis-reports',
            path: {
                'workspaceId': workspaceId,
            },
            query: {
                'createdAtDateBegin': createdAtDateBegin,
                'createdAtDateEnd': createdAtDateEnd,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'status': status,
                'userId': userId,
            },
        });
    }

    /**
     * Read one
     * @param workspaceId
     * @param id
     * @returns AnalysisReportDTO
     * @throws ApiError
     */
    public static backOfficeAnalysisReportControllerReadOne(
        workspaceId: string,
        id: string,
    ): CancelablePromise<AnalysisReportDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces/{workspaceId}/analysis-reports/{id}',
            path: {
                'workspaceId': workspaceId,
                'id': id,
            },
        });
    }

}
