/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeUserDTO } from '../models/MeUserDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * Me
     * @returns MeUserDTO
     * @throws ApiError
     */
    public static meControllerMe(): CancelablePromise<MeUserDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
        });
    }

    /**
     * Request password change
     * @param requestBody
     * @returns any Successful response
     * @throws ApiError
     */
    public static meControllerRequestPasswordChange(
        requestBody?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/me/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
