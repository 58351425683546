import { Button, ButtonIcon, ButtonSpinner, ButtonText } from '@gluestack-ui/themed';
import { FC } from 'react';
import { GestureResponderEvent, PressableStateCallbackType, StyleProp, ViewStyle } from 'react-native/types';
import { COLORS } from '../../theme/colors';

export interface ISecundaryButtonProps {
    onPress: ((event: GestureResponderEvent) => void);
    content?: string;
    isLoading?: boolean;
    isDisable?: boolean;
    width?: string | number;
    minWidth?: string | number;
    style?: StyleProp<ViewStyle> | ((state: PressableStateCallbackType) => StyleProp<ViewStyle>);
    leftIcon?: FC;
    testID?: string;
}
export const SecundaryButton: FC<ISecundaryButtonProps> = (props: ISecundaryButtonProps) => {
    return (
        <Button
            bg={COLORS.NEUTRAL[0]}
            $hover-bg={COLORS.NEUTRAL[60]}
            $_text-color={COLORS.ACCENT[100]}
            onPress={props.onPress}
            borderColor={COLORS.ACCENT[100]}
            borderWidth={1}
            isDisabled={props.isLoading === true || props.isDisable === true}
            width={props.width ?? 'fit-content'}
            minWidth={props.minWidth ?? 250}
            rounded="$lg"
            style={props?.style}
            testID={props?.testID}
        >
            {
                props.isLoading && (<ButtonSpinner mr="$1" testID={`${props?.testID && ""}spinner`}/>)
            }
            {
                !props.isLoading && props.leftIcon && (
                    <ButtonIcon as={() => <props.leftIcon/>}/>
                )
            }
            <ButtonText>{props.content}</ButtonText>
        </Button>
    )
}