import {
  Box,
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  Checkbox,
  CheckboxIcon,
  CheckboxIndicator,
  CheckboxLabel,
  ChevronRightIcon,
  CloseIcon,
  DownloadIcon,
  HStack,
  Icon,
  Image,
  Pressable,
  Select,
  SelectBackdrop,
  SelectContent,
  SelectDragIndicator,
  SelectDragIndicatorWrapper,
  SelectIcon,
  SelectInput,
  SelectItem,
  SelectPortal,
  SelectTrigger,
  Spinner,
  Text,
  Toast,
  ToastTitle,
  useToast,
  VStack,
} from '@gluestack-ui/themed';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CheckIcon, ChevronDown, EditIcon } from 'lucide-react-native';
import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';

import { ProgressBar } from '../components/analysis/ProgressBar';
import { PrimaryButton } from '../components/buttons/PrimaryButton';
import { Title } from '../components/Titles/Title';
import { TranslationContext } from '../contexts/translations/Translation';
import { UserContext } from '../contexts/User';
import { AnalysisDTO, AnalysisService } from '../generated/client-api-client';
import type { IAnalysisPageProps } from '../routes/Routes';
import { Routes } from '../routes/Routes';
import { COLORS } from '../theme/colors';
import { styles } from '../theme/styles';

export const AnalysisDetails: FC<IAnalysisPageProps> = (props: IAnalysisPageProps) => {
  const { navigation } = props;
  const { analysisId, testID } = props.route.params;
  const [analysisConfirmedResult, setAnalysisConfirmedResult] = useState<string>('');
  const [showAllResults, setShowAllResults] = useState<boolean>(false);
  const [refetchEvery5Secs, setRefetchEvery5Secs] = useState<boolean>(false);
  const { i18n } = useContext(TranslationContext);
  const user = useContext(UserContext);
  const NUMBER_OF_PATHOGEN_RESULTS_TO_SHOW = 3;

  const { close, show } = useToast();

  const showStartAnalysisJobErrorToast = () => {
    show({
      id: 'startAnalysisJobError',
      placement: 'bottom left',
      duration: 10_000,
      render: ({ id }) => (
        <Toast action="error" variant="accent" alignItems="center" gap={10}>
          <ToastTitle>{i18n?.t('page.analysis-details.toast.start-analysis-job-error')}</ToastTitle>
          <Pressable onPress={() => close(id)}>
            <Icon as={CloseIcon} />
          </Pressable>
        </Toast>
      ),
    });
  };

  const findConfirmedPathogen = (results?: Record<string, number>): string => {
    return Object.entries(results ?? {})?.sort((a, b) => b[1] - a[1])?.[0]?.[0];
  };

  const {
    data: analysis,
    isLoading: isAnalysisLoading,
    refetch: refetchAnalysis,
  } = useQuery<AnalysisDTO>({
    queryKey: ['analysis', analysisId, user],
    queryFn: async () => {
      const response = await AnalysisService.analysisControllerReadOne(user!.currentWorkspace!.id!, analysisId);

      // filter 100% results
      let isRedistributionNeeded = false;

      if (response) {
        const entries = Object.entries(response.results ?? {}).sort((a, b) => b[1] - a[1]);

        entries.forEach(([pathogen, probability], index) => {
          if (isRedistributionNeeded) {
            entries[index][1] += 0.01 / entries.length;
          }
          if (parseFloat((probability * 100).toFixed(2)) >= 99) {
            isRedistributionNeeded = true;
            entries[index][1] -= probability - 0.99;
          }
        });

        response.results = Object.fromEntries(entries);
      }

      setAnalysisConfirmedResult(findConfirmedPathogen(response.results));
      return response;
    },
    refetchInterval: refetchEvery5Secs ? 5_000 : 0,
  });

  useEffect(() => {
    if (analysis?.status === AnalysisDTO.status.PROCESSING) {
      setRefetchEvery5Secs(true);
    } else {
      setRefetchEvery5Secs(false);
    }
  }, [analysis]);

  const { mutateAsync: confirmPathogen, isPending: isConfirmPathogenLoading } = useMutation({
    mutationFn: async (analysisConfirmedResultToConfirm: string) => {
      return AnalysisService.analysisControllerConfirmResultAnalysis(user!.currentWorkspace!.id!, analysisId, { pathogenName: analysisConfirmedResultToConfirm });
    },
  });

  const { mutateAsync: startAnalysis, isPending: isStartAnalysisPending } = useMutation({
    mutationFn: async () => {
      try {
        await AnalysisService.analysisControllerStartAnalysis(user!.currentWorkspace!.id!, analysisId);
      } catch (error) {
        showStartAnalysisJobErrorToast();
      }
    },
  });

  const handleOnPressResult = (pathogen: string, probability: number) => {
    navigation.navigate(Routes.PATHOGEN_INFO, { pathogenName: pathogen, probability });
  };

  const ResultInformation: FC<{ pathogen: string; probability: number }> = (props: { pathogen: string; probability: number }) => {
    const { pathogen, probability } = props;
    return (
      <Box bg={COLORS.FOUNDATION_BACKGROUND_70} display="flex" flexDirection="row" marginTop={10} padding={10} borderRadius={10}>
        <VStack key={pathogen} flex={10} alignSelf="center">
          <HStack justifyContent="space-between">
            <Text testID={`${testID ?? ''}${AnalysisDetails.name}_name`} fontWeight="bold">
              {pathogen}{' '}
            </Text>
            <Text testID={`${testID ?? ''}${AnalysisDetails.name}_probability`}>{parseFloat((probability * 100).toFixed(2))}%</Text>
          </HStack>
          <ProgressBar progress={probability} />
        </VStack>
        <Pressable
          hover-bg={COLORS.FOUNDATION_BACKGROUND_80}
          bg={COLORS.FOUNDATION_BACKGROUND_70}
          onPress={() => handleOnPressResult(pathogen, probability)}
          display="flex"
          flexDirection="row"
          margin={10}
          padding={10}
          borderRadius={10}
        >
          <ChevronRightIcon flexGrow={1} alignSelf="center" />
        </Pressable>
      </Box>
    );
  };

  if (isAnalysisLoading) {
    return (
      <Box>
        <Spinner size={20} />
      </Box>
    );
  }

  return (
    <>
      {analysis && (
        <Box style={styles.layout}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
            <Center>
              <HStack>
                <Title title={i18n?.t('page.analysis-details.title')} />
                <Center margin={10}>
                  <Pressable
                    onPress={() => {
                      navigation.navigate(Routes.ANALYSIS_FORM, { analysisList: [analysis] });
                    }}
                    alignSelf="center"
                  >
                    <ButtonIcon as={EditIcon} size="md" />
                  </Pressable>
                </Center>
              </HStack>
            </Center>
            <Center>
              <Box style={{ backgroundColor: 'white', borderRadius: 10, padding: 10, shadowColor: '#171717', shadowOffset: { width: -2, height: 4 }, shadowOpacity: 0.2, shadowRadius: 3 }}>
                <Text>
                  {new Date(analysis.createdAt).toLocaleDateString(i18n?.locale)} | {new Date(analysis.createdAt).toLocaleTimeString(i18n?.locale)}
                </Text>
              </Box>
            </Center>
          </Box>
          <Box flexWrap="wrap" flexDirection="row" display="flex" style={{ marginVertical: 10, gap: 30, marginTop: 50 }}>
            <Image
              crossOrigin="anonymous"
              source={{ uri: analysis.imageUrl }}
              style={{
                aspectRatio: '1/1',
                borderRadius: 10,
                maxHeight: 450,
                maxWidth: 450,
                minHeight: 150,
                minWidth: 150,
                height: '100%',
                width: '100%',
                flexGrow: 1,
              }}
            />
            <VStack
              style={{
                backgroundColor: 'white',
                borderRadius: 10,
                paddingVertical: 20,
                paddingHorizontal: 30,
                shadowColor: '#171717',
                shadowOffset: { width: -2, height: 4 },
                shadowOpacity: 0.2,
                shadowRadius: 3,
                flexGrow: 1,
                overflow: 'scroll',
              }}
              maxHeight={500}
            >
              {analysis?.status === AnalysisDTO.status.IDLE && (
                <>
                  {(user?.scansInformation?.available ?? 0) === 0 && (
                    <VStack style={{ flex: 1, margin: 10 }}>
                      <Text fontWeight="bold">{i18n?.t('page.analysis-details.info-card.ask-for-more-scans')}</Text>
                    </VStack>
                  )}
                  {(user?.scansInformation?.available ?? 0) > 0 && (
                    <VStack style={{ flex: 1, margin: 10 }}>
                      <PrimaryButton
                        onPress={async () => {
                          startAnalysis();
                        }}
                        content={i18n?.t('page.analysis-details.start-scan.title')}
                        isLoading={isStartAnalysisPending}
                        minWidth={150}
                        width="auto"
                      />
                    </VStack>
                  )}
                </>
              )}
              {analysis?.status === AnalysisDTO.status.PROCESSING && (
                <VStack style={{ flex: 1, margin: 10 }}>
                  <Text fontWeight="bold">{i18n?.t('page.analysis-details.info-card.no-results')}</Text>
                  <Spinner size="large" />
                </VStack>
              )}
              {analysis?.status === AnalysisDTO.status.COMPLETED && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
                  <VStack>
                    <Text>{i18n?.t('page.analysis-details.info-card.analysis-is-confirmed')}</Text>
                    <Select selectedValue={analysisConfirmedResult} placeholder="" onValueChange={(e) => setAnalysisConfirmedResult(e)}>
                      <SelectTrigger size="md">
                        <SelectInput placeholder="" />
                        <SelectIcon mr="$3">
                          <Icon as={ChevronDown} />
                        </SelectIcon>
                      </SelectTrigger>
                      <SelectPortal>
                        <SelectBackdrop />
                        <SelectContent>
                          <SelectDragIndicatorWrapper>
                            <SelectDragIndicator />
                          </SelectDragIndicatorWrapper>
                          {Object.entries(analysis.results ?? {}).map(([pathogen]) => (
                            <SelectItem key={pathogen} label={pathogen} value={pathogen} />
                          ))}
                        </SelectContent>
                      </SelectPortal>
                    </Select>
                  </VStack>
                  <Box alignItems="flex-end" display="flex" flexDirection="row">
                    <PrimaryButton
                      onPress={async () => {
                        await confirmPathogen(analysisConfirmedResult);
                        await refetchAnalysis();
                      }}
                      content={i18n?.t('page.analysis-details.confirm-button.title')}
                      isLoading={isAnalysisLoading || isConfirmPathogenLoading}
                      minWidth={150}
                      width="auto"
                    />
                  </Box>
                </Box>
              )}
              {analysis?.status === AnalysisDTO.status.CONFIRMED && (
                <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                  <VStack>
                    <Text>{i18n?.t('page.analysis-details.info-card.analysis-confirmed')}</Text>
                    <Text>{analysis.confirmedResult}</Text>
                  </VStack>

                  <Box />

                  <Button
                    backgroundColor="transparent"
                    width={200}
                    alignSelf="end"
                    onPress={() => {
                      navigation.navigate(Routes.ANALYSIS_EXPORT, { analysis });
                    }}
                  >
                    <ButtonText paddingRight={4} style={{ color: 'red' }}>
                      {i18n?.t('page.analysis-details.export-to-pdf-button.title')}
                    </ButtonText>
                    <ButtonIcon as={DownloadIcon} color="red" />
                  </Button>
                </Box>
              )}

              {Object.keys(analysis.results ?? {}).length > 0 && (
                <Box marginTop={30}>
                  <Checkbox size="lg" value={`${showAllResults}`} isChecked={showAllResults} onChange={(isSelected: boolean) => setShowAllResults(isSelected)}>
                    <CheckboxIndicator mr="$2">
                      <CheckboxIcon as={CheckIcon} />
                    </CheckboxIndicator>
                    <CheckboxLabel>{i18n?.t('page.analysis-details.info-card.show-all-results')}</CheckboxLabel>
                  </Checkbox>
                  {Object.entries(analysis.results ?? {})
                    .slice(0, showAllResults ? Object.keys(analysis.results ?? {}).length : NUMBER_OF_PATHOGEN_RESULTS_TO_SHOW)
                    .map(([pathogen, probability]) => (
                      <ResultInformation key={pathogen} pathogen={pathogen} probability={probability} />
                    ))}
                </Box>
              )}
            </VStack>
          </Box>
        </Box>
      )}
    </>
  );
};
