/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableScanDetailsDTO } from '../models/AvailableScanDetailsDTO';
import type { CreateWorkspaceDTO } from '../models/CreateWorkspaceDTO';
import type { ScanDistributionPerWorkspaceListDTO } from '../models/ScanDistributionPerWorkspaceListDTO';
import type { UpdateWorkspaceDTO } from '../models/UpdateWorkspaceDTO';
import type { WorkspaceDTO } from '../models/WorkspaceDTO';
import type { WorkspaceListDTO } from '../models/WorkspaceListDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackOfficeWorkspaceService {

    /**
     * Archive
     * @param id
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerArchive(
        id: string,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/workspaces/{id}/archive',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Create
     * @param requestBody CreateWorkspaceDTO
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerCreate(
        requestBody?: CreateWorkspaceDTO,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/workspaces',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Search
     * @param country
     * @param description
     * @param name
     * @param pageNumber
     * @param pageSize
     * @param status
     * @param type
     * @returns WorkspaceListDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerSearch(
        country?: string,
        description?: string,
        name?: string,
        pageNumber?: number,
        pageSize?: number,
        status?: 'ACTIVE' | 'ARCHIVED' | 'DELETED',
        type?: 'B2B' | 'B2C',
    ): CancelablePromise<WorkspaceListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces',
            query: {
                'country': country,
                'description': description,
                'name': name,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'status': status,
                'type': type,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns any Successful response
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/back-office/workspaces/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Read one
     * @param id
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerReadOne(
        id: string,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update
     * @param id
     * @param requestBody UpdateWorkspaceDTO
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerUpdate(
        id: string,
        requestBody?: UpdateWorkspaceDTO,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/back-office/workspaces/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find workspace scan distribution strategy by ids
     * @param ids
     * @returns ScanDistributionPerWorkspaceListDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerFindWorkspaceScanDistributionStrategyByIds(
        ids: Array<string>,
    ): CancelablePromise<ScanDistributionPerWorkspaceListDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces/strategy',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * Unarchive
     * @param id
     * @returns WorkspaceDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerUnarchive(
        id: string,
    ): CancelablePromise<WorkspaceDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/workspaces/{id}/unarchive',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Users available scans
     * @param id
     * @param usersId
     * @returns AvailableScanDetailsDTO
     * @throws ApiError
     */
    public static backOfficeWorkspaceControllerUsersAvailableScans(
        id: string,
        usersId?: Array<Record<string, any>>,
    ): CancelablePromise<AvailableScanDetailsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/workspaces/{id}/users/available-scans',
            path: {
                'id': id,
            },
            query: {
                'usersId': usersId,
            },
        });
    }

}
