import { Alert, AlertIcon, AlertText, CloseIcon, Divider, Icon, Pressable, Toast, ToastTitle, useToast } from "@gluestack-ui/themed";
import { useMutation } from "@tanstack/react-query";
import { CameraIcon, InfoIcon, UploadIcon } from "lucide-react-native";
import React, { FC, useContext } from "react";
import { launchImageLibrary } from "react-native-image-picker";
import { MediaContext } from "../../contexts/media/Media";
import { TranslationContext } from "../../contexts/translations/Translation";
import { ImageProcessingService } from "../../generated/client-api-client";
import { COLORS } from "../../theme/colors";
import { TIFF_EXTENSION } from "../../utils/ImageConversionUtils";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { SecundaryButton } from "../buttons/SecundaryButton";
import { CameraPicker } from "./CameraPicker";

export interface ICameraActionsProps {
  handleSelectedConfirmed: () => Promise<void>;
  photoUriList: string[];
  setPhotoUriList: (photoUriList: string[]) => void;
  testID?: string;
}

const MAXIMUM_NUMBER_OF_FILES = 4;

export const CameraActions: FC<ICameraActionsProps> = (props: ICameraActionsProps) => {
  const { handleSelectedConfirmed, photoUriList, setPhotoUriList, testID } = props;
  const { i18n } = useContext(TranslationContext);
  const { closeStream, isCameraAllowed, hasCameraStarted, retakePhoto, startCamera, takePhoto } = useContext(MediaContext);

  const { close, show } = useToast();
  
  const { mutateAsync: convertTifToPng, isError, isPending } = useMutation({
    mutationFn: async (uri: string)=> {
      if (uri?.includes(TIFF_EXTENSION)) {
        return ImageProcessingService.imageProcessingControllerConvertTiffToPng({ base64Image: uri });
      } else {
        return uri;
      }
    }
  });

  const handleSelectPhoto = async () => {
    closeStream();
    setPhotoUriList([]);
    const result = await launchImageLibrary({ selectionLimit: MAXIMUM_NUMBER_OF_FILES, mediaType: 'photo' });
    if (!result?.assets || result?.assets?.length > MAXIMUM_NUMBER_OF_FILES) {
      return show({
        placement: 'bottom left',
        render: ({ id }) => (
          <Toast testID={`${testID ?? ''}${CameraActions.name}_toast_maximumNumberOfFilesError`} action='error' variant='accent' alignItems="center" gap={10}>
            <ToastTitle>{i18n.t('camera.max-number-of-files', { maximum: MAXIMUM_NUMBER_OF_FILES })}</ToastTitle>
            <Pressable onPress={() => close(id)}>
              <Icon as={CloseIcon} />
            </Pressable>
          </Toast>
        ),
      });
    }
    result.assets?.map(async (asset, index) => {
      const uri = await convertTifToPng(asset.uri!);
      photoUriList[index] = uri;
      setPhotoUriList([...photoUriList]);
    });
  }

  return (
    <>
      { photoUriList.length > 0 ? (
        <>
          <PrimaryButton testID={`${testID ?? ''}${CameraActions.name}_confirmSelected_button`} onPress={handleSelectedConfirmed} content={i18n.t('camera.use-photo', { count: photoUriList.length })} />
          <Divider />
          <SecundaryButton testID={`${testID ?? ''}${CameraActions.name}_retakePhoto_button`} onPress={() => retakePhoto(setPhotoUriList)} content={i18n.t('camera.retake-photo', { count: photoUriList.length })} />
        </>
      ) : (
        <>
          <CameraPicker testID={`${testID ?? ''}${CameraActions.name}_cameraPicker`} />
          <PrimaryButton testID={`${testID ?? ''}${CameraActions.name}_takePhoto_button`} isDisable={!isCameraAllowed} onPress={async () => { hasCameraStarted ? takePhoto(setPhotoUriList) : startCamera() }} content={hasCameraStarted ? i18n.t('camera.take-photo') : i18n.t('page.camera.open-camera')} leftIcon={() => <CameraIcon style={{ marginRight: 20 }} color={COLORS.NEUTRAL[0]} />} />
          <Divider width={250} />
          <PrimaryButton testID={`${testID ?? ''}${CameraActions.name}_selectPhoto_button`} isLoading={isPending} onPress={handleSelectPhoto} content={i18n.t('camera.choose-photo')} leftIcon={() => <UploadIcon style={{ marginRight: 20 }} color={COLORS.NEUTRAL[0]} />} />
          {isError && (
            <Alert testID={`${testID ?? ''}${CameraActions.name}_conversionError_alert`} mx="$2.5" action="error" variant="accent">
              <AlertIcon as={InfoIcon} mr="$3" />
              <AlertText>
                {i18n.t('camera.conversion-error')}
              </AlertText>
            </Alert>
          )}
        </>
      )}
    </>
  )
};
