import { FC, useContext, useState } from 'react';
import React from 'react';

import { AnalysisDTO } from '../generated/client-api-client';
import { ICameraWithStreamPageProps, Routes } from '../routes/Routes';
import { Box } from '@gluestack-ui/themed';
import { COLORS } from '../theme/colors';
import { Heading } from '@gluestack-ui/themed';
import { styles } from '../theme/styles';
import { CameraWithStream } from '../components/camera/CameraWithStream';
import { Media, MediaContext } from '../contexts/media/Media';
import { Carousel } from '../components/camera/Carousel';
import { CameraActions } from '../components/camera/CameraActions';
import { TranslationContext } from '../contexts/translations/Translation';

export const CameraPageWithStream: FC<ICameraWithStreamPageProps> = (props: ICameraWithStreamPageProps) => {
  const { navigation } = props;
  const { hasCameraStarted }: Media = useContext(MediaContext)!;
  const [photoUriList, setPhotoUriList] = useState<string[]>([]);

  const { i18n } = useContext(TranslationContext)

  const handleSelectedConfirmed = () => {
    const analysisList = photoUriList.map((photoUri) => ({
      createdAt: new Date().toISOString(),
      id: '',
      imageUrl: photoUri,
      name: '',
      status: AnalysisDTO.status.IDLE,
    } as AnalysisDTO));

    navigation.navigate(Routes.ANALYSIS_FORM, { analysisList });
  }

  return (
    <>
      <Box style={styles.layout}>
        <Heading size="xl">
          {i18n?.t('page.camera.title')}
        </Heading>
        <Box display='flex' flexDirection='row' flex={1} flexWrap='wrap' gap={80}>
          <Box
            borderRadius={20}
            flexGrow={3}
            flexShrink={3}
            flexBasis='$80'
            borderWidth={1}
            borderStyle='dashed'
            borderColor={COLORS.ACCENT[100]}
            bg={COLORS.NEUTRAL[60]}
            overflow='hidden'>
            {
              photoUriList.length > 0 && (
                <Carousel photoUriList={photoUriList} />
              )
            }
            {
              hasCameraStarted && !photoUriList.length && (
                <CameraWithStream setPhotoUriList={setPhotoUriList} />
              )
            }
          </Box>

          <Box
            flexGrow={1}
            flexShrink={1}
            flexBasis='350px'
            alignItems='center'
            justifyContent='center'
            display='flex'
            gap={30}
            flexDirection='column'
          >
            <CameraActions 
              handleSelectedConfirmed={async () => handleSelectedConfirmed()}
              photoUriList={photoUriList}
              setPhotoUriList={setPhotoUriList}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
