import { useEffect, useState } from 'react';
import { MediaContext } from './Media';

export interface IMediaContainerProps {
  children: any;
}

export const MediaContainer = ({ children }: IMediaContainerProps) => {
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[] | undefined>(undefined);
  const [canvas] = useState<HTMLCanvasElement>(document.createElement('canvas'));
  const [hasCameraStarted, setHasStartedCamera] = useState<boolean>(false);
  const [isCameraAllowed, setIsCameraAllowed] = useState<boolean | undefined>(undefined);
  const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(undefined);
  const [selectedCamera, setSelectedCamera] = useState<string | undefined>(undefined);
  const [video, setVideo] = useState<HTMLVideoElement | undefined>(undefined);

  useEffect(() => {
    allowCamera();
    listDevices();
    return () => closeStream();
  }, [])

  const allowCamera = async () => {
    const cameraPermissions = await window.navigator.permissions.query({ name: 'camera' });
    setIsCameraAllowed(cameraPermissions.state !== 'denied');
  }

  const changeCamera = async (deviceId: string) => {
    setHasStartedCamera(true);
    const stream = await window.navigator.mediaDevices.getUserMedia({ video: { deviceId } });
    setMediaStream(stream);
    const videoElement = document.querySelector('video#video');
    if (!videoElement) {
      return;
    }
    (videoElement as any).srcObject = stream;
    setVideo(videoElement);
  }

  const closeStream = async () => {
    mediaStream?.getVideoTracks()?.forEach((track: MediaStreamTrack) => {
      track.stop();
      mediaStream?.removeTrack(track);
    });

    setHasStartedCamera(false);
    setMediaStream(undefined);
    setVideo(undefined);
  }

  const listDevices = async () => {
    const deviceList = await window.navigator.mediaDevices.enumerateDevices();
    setCameraList(deviceList.filter((device) => device.kind === 'videoinput'));
  }

  const retakePhoto = (setPhotoUriList: (photoUriList: string[]) => void) => {
    setPhotoUriList([]);
    setHasStartedCamera(false);
  }

  const startCamera = async () => {
    const cameraPermissions = await window.navigator.permissions.query({ name: 'camera' });
    if (cameraPermissions.state === 'prompt') {
      cameraPermissions.onchange = async () => {
        if (cameraPermissions.state === 'granted') {
          setHasStartedCamera(true);
          await listDevices();
        } else {
          setIsCameraAllowed(false);
          setHasStartedCamera(false);
        }
      }
    } else if(cameraPermissions.state === 'granted') {
      setHasStartedCamera(true);
      await listDevices();
    } else {
      setIsCameraAllowed(false)
    }
    const stream = await window.navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedCamera } });
    setMediaStream(stream);
    const videoElement = document.querySelector('video#video');
    if (!videoElement) {
      return;
    }
    (videoElement as any).srcObject = stream;
    setVideo(videoElement);
  };

  const takePhoto = (setPhotoUriList: (photoUriList?: string[]) => void) => {
    if (!video) {
      return;
    }

    let context;
    const width = video.offsetWidth
    const height = video.offsetHeight;

    canvas.width = width;
    canvas.height = height;

    context = canvas.getContext('2d')!;
    context.drawImage(video, 0, 0, width, height);

    closeStream();
    setPhotoUriList([canvas.toDataURL('image/png')]);
  }

  const mySetMediaStream = (stream: MediaStream | undefined) => {
    setMediaStream(stream);
  }

  const mySetVideo = (htmlVideo: HTMLVideoElement | undefined) => {
    setVideo(htmlVideo);
  }

  return (
    <MediaContext.Provider value={{
      cameraList,
      changeCamera,
      closeStream,
      hasCameraStarted,
      isCameraAllowed,
      mediaStream,
      selectedCamera,
      setMediaStream: mySetMediaStream,
      setSelectedCamera,
      setVideo: mySetVideo,
      retakePhoto,
      startCamera,
      takePhoto,
      video,
    }}>
      {
        children
      }
    </MediaContext.Provider>
  );
}
