import { StyleSheet } from 'react-native';
import { COLORS } from './colors';

export const styles = StyleSheet.create({
    layout: {
      backgroundColor: COLORS.PRIMARY[80],
      flex: 1,
      paddingVertical: 40,
      paddingHorizontal: '5%',
      flexDirection: 'column',
      display: 'flex',
      borderRadius: 20,
      shadowColor: '#171717',
      shadowRadius: 3,
      shadowOpacity: 0.2,
      shadowOffset: { width: -1, height: 4 },
      gap: 10
    }
  });