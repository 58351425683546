import { Text, Linking } from "react-native";

export const urlify  = (text: string): React.JSX.Element =>{
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return (
        <Text>{
            parts.map((part, index)=>{
                if(urlRegex.test(part)){
                    return (
                    <Text key={index} style={{color: "blue"}} onPress={()=>{
                        Linking.openURL(part)
                    }}>
                        {part}
                    </Text>
                    );
                }else{
                    return part;
                }
            })
        }</Text>
    )
}