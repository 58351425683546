/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AnalysisReportDTO = {
    id: string;
    createdAt: string;
    expiresAt: string;
    filePath?: string;
    fileUrl?: string;
    query?: Record<string, any>;
    status: AnalysisReportDTO.status;
    updatedAt?: string;
    userId: string;
    workspaceId: string;
};

export namespace AnalysisReportDTO {

    export enum status {
        IDLE = 'IDLE',
        PROCESSING = 'PROCESSING',
        COMPLETED = 'COMPLETED',
        ERROR = 'ERROR',
    }


}

