import { Heading, Text, VStack } from '@gluestack-ui/themed';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useContext, type FC } from 'react';
import { StyleSheet } from 'react-native';
import { RootStackParamList, Routes } from '../../routes/Routes';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { Title } from '../Titles/Title';
import { TranslationContext } from '../../contexts/translations/Translation';

export interface IAppDescriptionProps {
}

export const AppDescription: FC<IAppDescriptionProps> = (props: IAppDescriptionProps) => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const { i18n } = useContext(TranslationContext);

  const handleStartAnalysisPress = async () => {
    navigation.navigate(Routes.CAMERA_WITH_STREAM, {});
  }

  return (
    <VStack style={styles.descriptionBox}>
      <Title title={i18n?.t('page.analysis.app-description.title')} />
      <Text size="md">{i18n?.t('page.analysis.app-description.description')}</Text>
      <PrimaryButton
        onPress={handleStartAnalysisPress}
        content={i18n?.t('page.analysis.app-description.start-analysis-button.label')}
        />
    </VStack>
  );
};

const styles = StyleSheet.create({
  descriptionBox: {
    width: '50%',
    marginVertical: 30,
    gap: 10
  },
});
