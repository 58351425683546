import { I18n } from 'i18n-js';
import { createContext } from 'react';

export interface Translation {
  i18n: I18n;
  changeLanguage: (locale: 'en' | 'fr') => Promise<void>;
}

export const TranslationContext = createContext<Translation>({
  i18n: new I18n(),
  changeLanguage: async () => {},
});
