import { useContext, useState } from 'react';
import { AuthContext } from './Auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { env } from '../../utils/Env';
import { MeService } from '../../generated/client-api-client';

export interface IAuthContainerProps {
  children: any;
}

const EXPIRATION = 'expiration';
const TOKEN = 'token'

export const AuthContainer = ({ children }: IAuthContainerProps) => {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const { data: isLoggedIn, isLoading } = useQuery<boolean, any, any>({
    queryKey: ['token'],
    queryFn: async () => {
      return !!(await AsyncStorage.getItem(TOKEN));
    },
    staleTime: 0,
    refetchInterval: 1_000,
  });

  const { mutateAsync: _logIn, isError: isErrorLogIn, isPending: isPendingLogIn } = useMutation({
    mutationFn: async (login: { email: string, password: string }) => {
      try {
        const token = await axios.post(`https://${env.auth0.domain}/oauth/token`, {
          grant_type: 'password',
          audience: env.auth0.audience,
          username: login.email,
          password: login.password,
          client_id: env.auth0.clientId,
        });

        const now = new Date();
        await AsyncStorage.setItem(EXPIRATION, new Date(now.getTime() + (Number(token.data.expires_in) * 1000)).toISOString());
        return await AsyncStorage.setItem(TOKEN, token.data.access_token);
      } catch (error) {
        if (error?.response?.status === 403 && error?.response?.data?.error_description === 'user is blocked') {
          setIsBlocked(true);
        }
        throw error;
      }
    }
  });

  const { mutateAsync: _requestChangePassword, isPending: isPendingRequestChangePassword } = useMutation({
    mutationFn: async (email: string) => {
      return MeService.meControllerRequestPasswordChange({email: email})      
    }
  });

  const { mutateAsync: _logOut, isPending: isPendingLogOut } = useMutation({
    mutationFn: async () => {
      return await AsyncStorage.setItem(TOKEN, '');
    }
  },);

  const logIn = async (email: string, password: string) => {
    await _logIn({ email, password });
  }

  const refetch = async () => {
  }

  const logOut = async () => {
    await _logOut();
  }

  const requestChangePassword = async (email: string) =>{
    await _requestChangePassword(email)
  }

  return (
    <AuthContext.Provider value={{
      isBlocked,
      isError: isErrorLogIn,
      isLogged: isLoggedIn,
      isLoading: isLoading || isPendingLogIn || isPendingLogOut,
      logIn,
      logOut,
      refetch,
      setIsBlocked,
      requestChangePassword: requestChangePassword
    }}>
      {
        children
      }
    </AuthContext.Provider>
  );
}

AuthContainer.Authorized = ({ children }: IAuthContainerProps) => {
  const auth = useContext(AuthContext);
  if (auth?.isLogged) {
    return children;
  }

  return null;
}

AuthContainer.Blocked = ({ children }: IAuthContainerProps) => {
  const auth = useContext(AuthContext);
  if (!auth?.isLogged && auth?.isBlocked) {
    return children;
  }

  return null;
}

AuthContainer.NotAuthorized = ({ children }: IAuthContainerProps) => {
  const auth = useContext(AuthContext);
  if (!auth?.isLogged && !auth?.isBlocked) {
    return children;
  }

  return null;
}
