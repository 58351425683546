/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AvailableScanDetailsDTO = {
    users: Array<any>;
    totalAvailable: number;
    totalConsumed: number;
    total: number;
    strategy: AvailableScanDetailsDTO.strategy;
};

export namespace AvailableScanDetailsDTO {

    export enum strategy {
        ON_DEMAND = 'ON_DEMAND',
        PER_USER = 'PER_USER',
        POOL = 'POOL',
    }


}

