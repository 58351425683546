/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BasicMedium {
    TSA = 'TSA',
    NA = 'NA',
    LB = 'LB',
    UNKNOWN = 'UNKNOWN',
}
