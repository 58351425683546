/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AnalysisHistoryDTO = {
    analysisId: string;
    createdAt: string;
    id: string;
    properties?: Record<string, any>;
    results?: Record<string, any>;
    status: AnalysisHistoryDTO.status;
    updatedAt?: string;
    workspaceId: string;
};

export namespace AnalysisHistoryDTO {

    export enum status {
        IDLE = 'IDLE',
        PROCESSING = 'PROCESSING',
        COMPLETED = 'COMPLETED',
        ERROR = 'ERROR',
        CONFIRMED = 'CONFIRMED',
    }


}

