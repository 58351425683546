/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateStrainDTO = {
    acidoAlcooloResistant?: string;
    archived?: boolean;
    biochemicalTestNeeded?: Record<string, any>;
    border?: string;
    branchId?: string;
    capsule?: string;
    classId?: string;
    classification?: string;
    commonName?: string;
    domainId?: string;
    elevation?: string;
    endosporeProduction?: string;
    familyId?: string;
    genderId?: string;
    gramColoration?: string;
    lineageIds?: Array<string>;
    maximumGrowthTemperature?: string;
    metadata?: Record<string, any>;
    minimumGrowthTemperature?: string;
    mp01?: string;
    mp02?: string;
    mp03?: string;
    mp04?: string;
    mp05?: string;
    mp06?: string;
    mp07?: string;
    mp08?: string;
    mp09?: string;
    mp10?: string;
    mp11?: string;
    mp12?: string;
    mp13?: string;
    mp14?: string;
    mp15?: string;
    mp16?: string;
    mp17?: string;
    mp18?: string;
    mp19?: string;
    mp20?: string;
    mp21?: string;
    mp22?: string;
    mp23?: string;
    mp24?: string;
    mp25?: string;
    mp26?: string;
    mp27?: string;
    mp28?: string;
    mp29?: string;
    mp30?: string;
    mp31?: string;
    mp32?: string;
    mp33?: string;
    mp34?: string;
    mp35?: string;
    mp36?: string;
    mp37?: string;
    mp38?: string;
    mp39?: string;
    mp40?: string;
    mp41?: string;
    mp42?: string;
    mp43?: string;
    mp44?: string;
    mp45?: string;
    mp46?: string;
    mp47?: string;
    mp4701?: string;
    mp4702?: string;
    mp4703?: string;
    mp4704?: string;
    mp4705?: string;
    mp4706?: string;
    mp4707?: string;
    mp4708?: string;
    mp4709?: string;
    mp4710?: string;
    mp4711?: string;
    mp4712?: string;
    mp4713?: string;
    mp4714?: string;
    mp4715?: string;
    mp4716?: string;
    mp4717?: string;
    mp4718?: string;
    mp4719?: string;
    mp4720?: string;
    mp4721?: string;
    mp48?: string;
    mp49?: string;
    mp50?: string;
    mp51?: string;
    mp52?: string;
    mp53?: string;
    mp54?: string;
    mp55?: string;
    mp56?: string;
    mp57?: string;
    mp58?: string;
    mp59?: string;
    mp60?: string;
    mp61?: string;
    mp62?: string;
    mp63?: string;
    mp64?: string;
    mp65?: string;
    mp66?: string;
    mp67?: string;
    mp68?: string;
    mp69?: string;
    mp70?: string;
    mp71?: string;
    mp72?: string;
    mp73?: string;
    mp74?: string;
    mp75?: string;
    mp76?: string;
    nucleotide?: string;
    optimalGrowthTemperature?: string;
    orderId?: string;
    oxygen?: string;
    protein?: string;
    proteinGroup?: string;
    scientificName?: string;
    shape?: string;
    species?: string;
    status?: UpdateStrainDTO.status;
    taxonomy?: string;
    tsa37deg?: string;
};

export namespace UpdateStrainDTO {

    export enum status {
        APPROVED = 'APPROVED',
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
    }


}

