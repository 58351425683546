/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MediaQueryParams = {
    archived?: boolean;
    deviceType?: string;
    microscopeType?: string;
    pageNumber?: number;
    pageSize?: number;
    reactants?: string;
    status?: MediaQueryParams.status;
    strainId?: string;
    title?: string;
    zoom?: number;
};

export namespace MediaQueryParams {

    export enum status {
        APPROVED = 'APPROVED',
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
    }


}

