import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AnalysisDTO } from '../generated/client-api-client';

export enum Routes {
  ANALYSIS = 'Analysis',
  ANALYSIS_EXPORT = 'AnalysisExport',
  ANALYSIS_FORM = 'AnalysisForm',
  ANALYSIS_LIST = 'AnalysisList',
  BLOCKED_USER = 'BlockedUser',
  CAMERA = 'Camera',
  CAMERA_WITH_STREAM = 'CameraWithStream',
  LOGIN ='Login',
  PATHOGEN_INFO = 'PathogenInfo',
}

export type RootStackParamList = {
  Analysis: { analysisId: string, testID?: string },
  AnalysisExport: { analysis: AnalysisDTO, testID?: string },
  AnalysisForm: { analysisList: AnalysisDTO[], testID?: string },
  AnalysisList: { refresh?: boolean, testID?: string, watchAnalysisIds?: string[] },
  BlockedUser: { testID?: string },
  Camera: { testID?: string },
  CameraWithStream: { testID?: string },
  Login: { testID?: string },
  PathogenInfo: { pathogenName: string, probability: number, testID?: string },
};

export type IAnalysisPageProps = NativeStackScreenProps<RootStackParamList, 'Analysis'>;
export type IAnalysisExportPageProps = NativeStackScreenProps<RootStackParamList, 'AnalysisExport'>;
export type IAnalysisFormPageProps = NativeStackScreenProps<RootStackParamList, 'AnalysisForm'>;
export type IAnalysisListPageProps = NativeStackScreenProps<RootStackParamList, 'AnalysisList'>;
export type IBlockedUserPageProps = NativeStackScreenProps<RootStackParamList, 'BlockedUser'>;
export type ICameraPageProps = NativeStackScreenProps<RootStackParamList, 'Camera'>;
export type ICameraWithStreamPageProps = NativeStackScreenProps<RootStackParamList, 'CameraWithStream'>;
export type ILoginPageProps = NativeStackScreenProps<RootStackParamList, 'Login'>;
export type IPathogenInfoPageProps = NativeStackScreenProps<RootStackParamList, 'PathogenInfo'>;
