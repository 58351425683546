import { createContext } from "react";
import { AvailableScanDTO, MeUserDTO, WorkspaceDTO } from "../generated/client-api-client";

export interface User {
  currentWorkspace: WorkspaceDTO | null;
  isError: boolean;
  isLoading: boolean;
  setCurrentWorkspace: (id: string) => void;
  user: MeUserDTO | null;
  workspaceList: WorkspaceDTO[] | null;
  scansInformation: AvailableScanDTO | undefined;
  isScansInformationLoading: boolean;
}

export const UserContext = createContext<User | undefined>(undefined);
