import { Input, InputField, Text, View } from "@gluestack-ui/themed";
import { FC } from "react";

export interface IFormTemperatureProps {
  onChange: (val: string) => void,
  value?: string,
  isDisabled?: boolean;
}

export const FormTemperature: FC<IFormTemperatureProps> = ({isDisabled, value, onChange}: IFormTemperatureProps) => {
  const handleChange = (val: string) => {
    onChange(val.replace(',', '.'));
  }

  return (
    <View
      flexDirection="row"
      width="100%"
      gap={4}
    >
        <Input
          variant="outline"
          size="md"
          isDisabled={isDisabled}
          width="90%">
          <InputField keyboardType="numeric" value={value} onChangeText={handleChange}></InputField>
        </Input>
        <Text style={{ flex: 1, marginVertical: 10 }} alignSelf="center">
          ˚C
        </Text>
        </View>
  );
};