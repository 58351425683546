import { Box, ScrollView, Spinner, Text, VStack } from '@gluestack-ui/themed';
import { useContext, type FC } from 'react';
import { StyleSheet } from 'react-native';
import type { AnalysisDTO } from '../../generated/client-api-client';
import { AnalysisHistoryListEmptyItem } from './AnalysisHistoryListEmptyItem';
import { AnalysisHistoryListItem } from './AnalysisHistoryListItem';
import { TranslationContext } from '../../contexts/translations/Translation';

export interface IAnalysisHistoryListProps {
  isLoading: boolean;
  data?: AnalysisDTO[];
}

export const AnalysisHistoryList: FC<IAnalysisHistoryListProps> = (props: IAnalysisHistoryListProps) => {
  const { data } = props;
  const { i18n } = useContext(TranslationContext);

  if (props.isLoading) {
    return (
      <Box>
        <Spinner size={20} />
      </Box>
    );
  }

  return (
    <VStack style={styles.layout}>
      <ScrollView>
        <Text style={{ margin: 10 }}>{i18n?.t('page.analysis.history-list.title')}</Text>
        {data?.length === 0 && <AnalysisHistoryListEmptyItem key="empty" />}
        {data?.map((analysis: AnalysisDTO, index) => (
          <AnalysisHistoryListItem key={index} analysis={analysis} />
        ))}
      </ScrollView>
    </VStack>

  );
};

const styles = StyleSheet.create({
  layout: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'white',
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
});
