import {
  Box,
  Heading,
  Spinner,
  VStack
} from '@gluestack-ui/themed';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import type { FC } from 'react';
import React, { useContext, useState } from 'react';
import { LayoutChangeEvent, Linking } from 'react-native';
import { COLORS } from '../theme/colors';
import { styles } from '../theme/styles';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Text } from '@gluestack-ui/themed';
import { Image } from '@gluestack-ui/themed';
import { ImageProcessingService, MediaDTO, MediaService, StrainDTO, StrainService } from '../generated/client-api-client';
import { BiochemicalTestMap, CharacteristicMap, PathogeniCityMap, SimplifiedLiteratureMap, SourceMap, TaxonomyMap } from '../utils/MapUtils';
import { TerciaryButton } from '../components/buttons/TerciaryButton';
import { ArrowLeft } from 'lucide-react-native';
import { convertImageToBase64 } from '../utils/ImageConversionUtils';
import { pascalToSnake } from '../utils/StringUtils';
import AnalysisDetailsTestTab from '../components/analysis/details/AnalysisDetailsTestTab';
import { TranslationContext } from '../contexts/translations/Translation';
import { urlify } from '../utils/UrlUtils';
import { IPathogenInfoPageProps } from '../routes/Routes';

const Tab = createMaterialTopTabNavigator();

export const PathogenDetailPage: FC<IPathogenInfoPageProps> = (props: IPathogenInfoPageProps) => {
  const { navigation } = props;
  const { pathogenName, probability, testID } = props.route.params;
  const { i18n } = useContext(TranslationContext);

  const [boxLayoutWidth, setBoxLayoutWidth] = useState<number | undefined>();

  const [imageErrorMessage, setImageErrorMessage] = useState<boolean>(false);
  
  const { data: pathogen, isLoading: isPathogenLoading } = useQuery<StrainDTO & { uri: string }>({
    queryKey: ['pathogen', pathogenName],
    queryFn: async () => {
      const strain = (await StrainService.strainControllerSearch(pathogenName, undefined, 0, 1)).data[0]
      const imageData = (await MediaService.mediaControllerSearch(undefined, undefined, undefined, 0, 1, undefined, MediaDTO.status.APPROVED, strain.id)).data[0]

      let uri: string = "";
      if (imageData) {
        if (imageData.imageUrl.includes(".tif")) {
          const base64Image = await convertImageToBase64(imageData.imageUrl)
          uri = await ImageProcessingService.imageProcessingControllerConvertTiffToPng({ base64Image });
        } else {
          uri = imageData.imageUrl;
        }
      }

      return {
        ...strain,
        uri
      };
    }
  });

  if (isPathogenLoading) {
    return (
      <Box>
        <Spinner size={20} testID={`${testID && ""}${PathogenDetailPage.name}_spinner`}/>
      </Box>
    );
  }

  const ListProcess = ({ listProperties, isSource = false }: { listProperties: string[], isSource?: boolean }) => {
    const keys: ResultKeys[] = Object.keys(pathogen ?? {})
      .filter((key: string) => listProperties.includes(key)) as ResultKeys[];

    return (
      <Box
        bgColor={COLORS.NEUTRAL[0]}
        flex={1}
        display='flex'
        flexDirection='column'
        gap={20}
        flexWrap='nowrap'
        paddingVertical={30}
        paddingHorizontal={20}
        overflow='scroll'
        flexBasis={0}
      >

        {
          keys.map((key: ResultKeys) => {
            return(
              <Box key={key}>
                <Text color={COLORS.NEUTRAL[80]}>
                  {i18n?.t(`properties.${pascalToSnake(key)}`)}
                </Text>
                <Text style={{ borderBottomWidth: 1, borderBottomColor: COLORS.NEUTRAL[70] }} testID={`${testID ?? ''}${PathogenDetailPage.name}_url`}>
                  {isSource ? urlify(pathogen?.[key]?.toString() ?? "") : pathogen?.[key]?.toString()}
                </Text>
              </Box>
            )
        })
        }
      </Box>
    )
  }


  return (
    <>
      {
        pathogen && (
          <Box style={styles.layout} >
            <TerciaryButton style={{ margin: 0, padding: 0 }} leftIcon={() => <ArrowLeft size='16px' strokeWidth='1.25px' color={COLORS.ACCENT[100]} />} content={i18n?.t('page.pathogen-details.go-back')} onPress={async () => navigation.goBack()} />
            <Heading size="xl" testID={`${testID && ""}${PathogenDetailPage.name}_pathogen`}>
              {pathogen.scientificName} - {(probability * 100).toFixed(2)}%
            </Heading>
            <Text >{i18n?.t('page.pathogen-details.image-description')}</Text>
            <Box flexWrap='wrap' flexDirection='row' display='flex' style={{ marginBottom: 10, gap: 30, marginTop: 10 }}>
              {!imageErrorMessage ? (<Image
                source={pathogen.uri}
                style={{
                  aspectRatio: '1/1',
                  borderRadius: 10,
                  maxHeight: 450,
                  maxWidth: 450, minHeight: 150, minWidth: 150, height: '100%', width: '100%',
                  flexGrow: 1
                }}
                onError={() => { setImageErrorMessage(true) }}
              />) : <Text style={{ maxWidth: 450, minWidth: 450 }} testID={`${testID ?? ''}${PathogenDetailPage.name}_noImages`}>{i18n?.t('page.pathogen-details.no-images-found')}</Text>}
              <VStack
                style={{
                  backgroundColor: 'white',
                  borderRadius: 10,
                  maxHeight: 450,
                  minHeight: 450, height: '100%',
                  paddingVertical: 20,
                  paddingHorizontal: 30,
                  shadowColor: '#171717',
                  shadowOffset: { width: -2, height: 4 },
                  shadowOpacity: 0.2,
                  shadowRadius: 3,
                  flexGrow: 3,
                  overflow: 'hidden',
                  flex: 3,
                  flexShrink: 3
                }}
                onLayout={(event: LayoutChangeEvent) => {
                  const { width } = event.nativeEvent.layout;
                  setBoxLayoutWidth(width)
                }}
              >
                <Tab.Navigator
                  style={{
                    flex: 1,
                    width: boxLayoutWidth ? boxLayoutWidth - 60 : 450,
                    display: 'flex'
                  }}
                  screenOptions={{
                    tabBarLabelStyle: { fontSize: 12, color: COLORS.ACCENT[100] },
                    tabBarItemStyle: { width: 110, borderBottomColor: COLORS.ACCENT[100], },
                    tabBarStyle: { backgroundColor: 'transparent' },
                    tabBarIndicatorStyle: { backgroundColor: COLORS.ACCENT[100] }
                  }}
                >
                  <Tab.Screen
                    name={i18n?.t('page.pathogen-details.details-card.tabs.description') as string}
                    component={() => <Box
                      bgColor={COLORS.NEUTRAL[0]}
                      flex={1}
                      display='flex'
                      flexDirection='column'
                      gap={20}
                      flexWrap='nowrap'
                      paddingVertical={30}
                      paddingHorizontal={20}
                      overflow='scroll'
                      flexBasis={0}
                    >
                      <Box key={"common_name"}>
                        <Text color={COLORS.NEUTRAL[80]}  >
                          {i18n?.t(`properties.common_name`)}
                        </Text>
                        <Text style={{ borderBottomWidth: 1, borderBottomColor: COLORS.NEUTRAL[70] }} testID={`${testID && ""}${PathogenDetailPage.name}_commonName`}>
                          {pathogen.commonName}
                        </Text>
                      </Box>
                      <Box key={"scientific_name"}>
                        <Text color={COLORS.NEUTRAL[80]}>
                          {i18n?.t(`properties.scientific_name`)}
                        </Text>
                        <Text style={{ borderBottomWidth: 1, borderBottomColor: COLORS.NEUTRAL[70] }} testID={`${testID && ""}${PathogenDetailPage.name}_scientificName`}>
                          {pathogen.scientificName}
                        </Text>
                      </Box>
                      <Box key={"lineage"}>
                        <Text color={COLORS.NEUTRAL[80]}>
                          {i18n?.t(`properties.lineage`)}
                        </Text>
                        <Text style={{ borderBottomWidth: 1, borderBottomColor: COLORS.NEUTRAL[70] }} testID={`${testID && ""}${PathogenDetailPage.name}_lineages`}>
                          {pathogen.lineage?.map(p => p.name).join(" > ")}
                        </Text>
                      </Box>
                    </Box>
                    }
                  />
                  <Tab.Screen name={i18n?.t('page.pathogen-details.details-card.tabs.biochemical-tests') as string} component={() => <AnalysisDetailsTestTab listProperties={BiochemicalTestMap} pathogen={pathogen}/>} />
                  <Tab.Screen name={i18n?.t('page.pathogen-details.details-card.tabs.characteristics') as string} component={() => <ListProcess listProperties={CharacteristicMap} />} />
                  <Tab.Screen name={i18n?.t('page.pathogen-details.details-card.tabs.pathogenicity') as string} component={() => <ListProcess listProperties={PathogeniCityMap} />} />
                  <Tab.Screen name={i18n?.t('page.pathogen-details.details-card.tabs.literature') as string} component={() => <ListProcess listProperties={SimplifiedLiteratureMap}/>} />
                  <Tab.Screen name={i18n?.t('page.pathogen-details.details-card.tabs.source') as string} component={() => <ListProcess listProperties={SourceMap} isSource={true}/>} />
                </Tab.Navigator>
              </VStack>
            </Box>
          </Box>
        )}
    </>
  );
};

type ResultKeys = keyof (StrainDTO & { uri: string });