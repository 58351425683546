/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateUserDTO = {
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    role?: CreateUserDTO.role;
    status?: CreateUserDTO.status;
    zohoId?: string;
};

export namespace CreateUserDTO {

    export enum role {
        CLIENT_USER = 'client-user',
        ADMIN_USER = 'admin-user',
        BACKOFFICE_USER = 'backoffice-user',
    }

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }


}

