import { Center, Heading, HStack, Image,  Text, VStack, View, Pressable, Badge, BadgeText } from '@gluestack-ui/themed';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ChevronRightIcon } from 'lucide-react-native';
import { useContext, type FC } from 'react';
import { StyleSheet } from 'react-native';
import { AnalysisDTO } from '../../generated/client-api-client';
import { RootStackParamList, Routes } from '../../routes/Routes';
import { COLORS } from '../../theme/colors';
import { Title } from '../Titles/Title';
import { TranslationContext } from '../../contexts/translations/Translation';

const bacteriaImage = require('../../assets/bacteria.png');

export interface IAnalysisHistoryListItemProps {
  analysis: AnalysisDTO;
  testID?: string;
}

type analysisStatus = {
  [key in AnalysisDTO.status] : {
    action: "error" | "warning" | "success" | "info" | "muted",
    name: string
  }
}

export const AnalysisHistoryListItem: FC<IAnalysisHistoryListItemProps> = (props: IAnalysisHistoryListItemProps) => {
  const { analysis, testID } = props;
  const { i18n } = useContext(TranslationContext);
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const handleAnalysisClicked = (id: string) => {
    navigation.navigate(Routes.ANALYSIS, { analysisId: id });
  };

  const ANALYSIS_STATUS: analysisStatus = {
    [AnalysisDTO.status.COMPLETED]: {
      action: "info",
      name: "status.success"
    },
    [AnalysisDTO.status.ERROR]: {
      action: "error",
      name: "status.error"
    },
    [AnalysisDTO.status.IDLE]: {
      action: "muted",
      name: "status.idle"
    },
    [AnalysisDTO.status.CONFIRMED]: {
      action: "success",
      name: "status.confirmed"
    },
    [AnalysisDTO.status.PROCESSING]: {
      action: "warning",
      name: "status.processing"
    },
  }

  return (
    <Pressable
      onPress={() => handleAnalysisClicked(analysis.id)}
      bg={COLORS.FOUNDATION_PRIMARY}
      $hover-bg={COLORS.FOUNDATION_PRIMARY_100}
      style={styles.layout}
      testID={`${testID ?? ''}${AnalysisHistoryListItem.name}_analysis`}
    >
      <HStack>
        <Center>
          <Image crossOrigin='anonymous' source={{ uri: analysis.imageUrl ?? bacteriaImage }} style={styles.image} alt="analysis image" />
        </Center>
        <VStack style={styles.itemInformation}>
          <HStack alignItems='center'>
            <Title size='md' title={`${analysis.code}: `} />
            <Text >{`${analysis.name}`}</Text>
          </HStack>
          <Text>{i18n?.t('page.analysis.history-list.analysis-information-description', { date: new Date(analysis.createdAt).toLocaleString(i18n.locale) })}</Text>
        </VStack>
        <View justifyContent='center'>
          <Badge
            variant="outline" 
            borderRadius={20} 
            action={ANALYSIS_STATUS[analysis.status].action}
            width={110}
            justifyContent='center'
          >
            <BadgeText>{i18n?.t(ANALYSIS_STATUS[analysis.status].name)}</BadgeText>
          </Badge>
        </View>
        <Center style={{ flex: 1 }}>
          <ChevronRightIcon />
        </Center>
      </HStack>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  layout: {
    borderColor: 'black',
    borderRadius: 10,
    marginVertical: 5,
    marginRight: 50,
    padding: 5,
  },
  image: {
    width: 50,
    height: 50,
    margin: 10,
  },
  itemInformation: {
    flex: 8,
    margin: 10,
  },
});
