/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum HemolyticProperty {
    BETA = 'BETA',
    GAMMA = 'GAMMA',
    NONE = 'NONE',
    UNKNOWN = 'UNKNOWN',
}
