/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserQueryParams = {
    email?: string;
    firstName?: string;
    lastName?: string;
    pageNumber?: number;
    pageSize?: number;
    status?: UserQueryParams.status;
};

export namespace UserQueryParams {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }


}

