/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AnalysisDTO = {
    code: string;
    createdAt: string;
    id: string;
    imageUrl?: string;
    name: string;
    properties?: Record<string, any>;
    results?: Record<string, any>;
    status: AnalysisDTO.status;
    updatedAt?: string;
    user?: any;
    workspace?: any;
    confirmedDate?: string;
    confirmedResult?: string;
    sampleId: string;
    receptionDate: string;
    sampleTemperature: number;
};

export namespace AnalysisDTO {

    export enum status {
        IDLE = 'IDLE',
        PROCESSING = 'PROCESSING',
        COMPLETED = 'COMPLETED',
        ERROR = 'ERROR',
        CONFIRMED = 'CONFIRMED',
    }


}

