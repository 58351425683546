import { NavigationProp, useNavigation } from '@react-navigation/native';
import { FC, useState, useContext } from 'react';
import React from 'react';

import { AnalysisDTO } from '../generated/client-api-client';
import { ICameraPageProps, RootStackParamList, Routes } from '../routes/Routes';
import { Box, Divider, Image } from '@gluestack-ui/themed';
import { Alert } from 'react-native';
import { COLORS } from '../theme/colors';
import { PrimaryButton } from '../components/buttons/PrimaryButton';
import { Camera } from 'expo-camera';
import { ChronoCamera } from '../components/camera/Camera';
import { SecundaryButton } from '../components/buttons/SecundaryButton';
import { launchImageLibrary } from 'react-native-image-picker';
import { CameraIcon, UploadIcon } from 'lucide-react-native';
import { Heading } from '@gluestack-ui/themed';
import { styles } from '../theme/styles';
import { ImageProcessingService } from '../generated/client-api-client';
import { Title } from '../components/Titles/Title';
import { TranslationContext } from '../contexts/translations/Translation';
import { TIFF_EXTENSION } from '../utils/ImageConversionUtils';

export const CameraPage: FC<ICameraPageProps> = (props: ICameraPageProps) => {
  const { navigation } = props;
  const [photoUri, setPhotoUri] = useState<string | undefined>(undefined);
  const [hasCameraStarted, setHasStartedCamera] = useState<boolean>(false);
  const [camera, setCamera] = useState<Camera | null | undefined>(undefined);

  const { i18n } = useContext(TranslationContext);

  const handleStartCamera = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      setHasStartedCamera(true);
    } else {
      Alert.alert('Access denied');
    }
  };

  const handlePictureTakenOrUpdaloaded = (image: { uri: string }) => {
    setPhotoUri(image.uri)

  };

  const handleSelectedConfirmed = () => {
    navigation.navigate(Routes.ANALYSIS_FORM, {
      analysisList: [{
        createdAt: new Date().toISOString(),
        id: '',
        imageUrl: photoUri,
        name: '',
        status: AnalysisDTO.status.IDLE,
      }] as unknown as AnalysisDTO[],
    })
  }

  const handleRetakePhoto = () => {
    setPhotoUri(undefined);
    setCamera(undefined);
    setHasStartedCamera(false);
  }

  const handleChoosePhoto = async () => {
    setPhotoUri(undefined);
    setCamera(undefined);
    setHasStartedCamera(false);
    const result = await launchImageLibrary({ selectionLimit: 1, mediaType: 'photo' });
    if ((result?.assets?.length ?? 0) > 0) {
      if (result?.assets?.[0]?.uri?.includes(TIFF_EXTENSION)) {
        const jpgImage = await ImageProcessingService.imageProcessingControllerConvertTiffToPng({ base64Image: result.assets?.[0]?.uri ?? '' });
        setPhotoUri(jpgImage);
      } else {
        setPhotoUri(result.assets?.[0]?.uri);
      }
    }
  }

  const Options = () => {
    if (!photoUri) {
      return (
        <>
          <PrimaryButton onPress={async () => { await handleStartCamera() }} content="Take Photo" leftIcon={() => <CameraIcon style={{ marginRight: 20 }} color={COLORS.NEUTRAL[0]} />} />

          <Divider width={250} />

          <PrimaryButton onPress={async () => { handleChoosePhoto() }} content="Choose a Photo" leftIcon={() => <UploadIcon style={{ marginRight: 20 }} color={COLORS.NEUTRAL[0]} />} />
        </>
      )
    }
    return (
      <>
        <PrimaryButton onPress={async () => { handleSelectedConfirmed() }} content="Use this photo" />

        <Divider />

        <SecundaryButton onPress={async () => { handleRetakePhoto() }} content="Retake" />

      </>
    )
  }

  return (
    <Box style={styles.layout}>
      {/* <Heading size="xl">
        {i18n.t('page.camera.title')}
      </Heading> */}
      <Title title={i18n?.t('page.camera.title') || ""}/>
      <Box display='flex' flexDirection='row' flex={1} flexWrap='wrap' gap={80}>

        <Box
          borderRadius={20}
          flexGrow={3}
          flexShrink={3}
          flexBasis='$80'
          borderWidth={1}
          borderStyle='dashed'
          borderColor={COLORS.ACCENT[100]}
          bg={COLORS.NEUTRAL[60]}
          overflow='hidden'>
          {
            photoUri && (
              <Image crossOrigin='anonymous' width='100%' height='100%' source={{ uri: photoUri }} />
            )
          }
          {
            hasCameraStarted && !photoUri && (
              <ChronoCamera camera={camera} onPhotoTaken={async (image) => handlePictureTakenOrUpdaloaded(image)} />
            )
          }
        </Box>

        <Box
          flexGrow={1}
          flexShrink={1}
          flexBasis='350px'
          alignItems='center'
          justifyContent='center'
          display='flex'
          gap={30}
          flexDirection='column'
        >
          <Options />
        </Box>
      </Box>
    </Box>
  );
};
