/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserDTO = {
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    status: UserDTO.status;
    updatedAt?: string;
    workspace: Record<string, any>;
    zohoId?: string;
};

export namespace UserDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }


}

