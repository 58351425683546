/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InviteUserDTO = {
    email: string;
    role?: InviteUserDTO.role;
};

export namespace InviteUserDTO {

    export enum role {
        CLIENT_USER = 'client-user',
        ADMIN_USER = 'admin-user',
        BACKOFFICE_USER = 'backoffice-user',
    }


}

