/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkDataImportDTO } from '../models/BulkDataImportDTO';
import type { IsImportingDataDTO } from '../models/IsImportingDataDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackOfficeBulkDataImportService {

    /**
     * Import bulk
     * @param requestBody BulkDataImportDTO
     * @returns any Successful response
     * @throws ApiError
     */
    public static backOfficeBulkDataImportControllerImportBulk(
        requestBody?: BulkDataImportDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/bulkDataImport/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Is importing data
     * @returns IsImportingDataDTO
     * @throws ApiError
     */
    public static backOfficeBulkDataImportControllerIsImportingData(): CancelablePromise<IsImportingDataDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/bulkDataImport/isImportingData',
        });
    }

}
