/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreatedMediaDTO = {
    uploadURL: string;
    archived: boolean;
    createdAt: string;
    deviceType: string;
    id: string;
    imageUrl: string;
    metadata?: Record<string, any>;
    microscopeType: string;
    reactants?: string;
    status: CreatedMediaDTO.status;
    title?: string;
    zoom: number;
};

export namespace CreatedMediaDTO {

    export enum status {
        APPROVED = 'APPROVED',
        PENDING = 'PENDING',
        REJECTED = 'REJECTED',
    }


}

