/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SyncDTO } from '../models/SyncDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BiologicalService {

    /**
     * Sync data
     * @returns SyncDTO
     * @throws ApiError
     */
    public static biologicalControllerSyncData(): CancelablePromise<SyncDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data/syncData',
        });
    }

    /**
     * Is syncing
     * @returns any Successful response
     * @throws ApiError
     */
    public static biologicalControllerIsSyncing(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/data/isSyncing',
        });
    }

}
