import { Heading, HStack, VStack } from '@gluestack-ui/themed';
import { useContext, type FC } from 'react';
import { StyleSheet } from 'react-native';
import Internationalization from '../../translations/Internationalization';
import { TranslationContext } from '../../contexts/translations/Translation';

export interface IAnalysisHistoryListEmptyItemProps {
  testID?: string;
}

export const AnalysisHistoryListEmptyItem: FC<IAnalysisHistoryListEmptyItemProps> = (props: IAnalysisHistoryListEmptyItemProps) => {
  const { testID } = props;
  const { i18n } = useContext(TranslationContext);

  return (
    <HStack style={styles.layout}>
      <VStack style={styles.itemInformation}>
        <Heading size="md"  testID={`${testID ?? ''}${AnalysisHistoryListEmptyItem.name}_empty`}>{i18n?.t('page.analysis.history-list.empty-analysis-list')}</Heading>
      </VStack>
    </HStack>
  );
};

const styles = StyleSheet.create({
  layout: {
    backgroundColor: 'lightgray',
    borderColor: 'black',
    borderRadius: 10,
    margin: 10,
    padding: 10,
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  image: {
    width: 100,
    height: 100,
  },
  itemInformation: {
    flex: 8,
    padding: 10,
  },
});
