/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ScanTokenDTO = {
    assignedUser?: any;
    assignedWorkspace?: any;
    consumedBy?: any;
    consumedOn?: any;
    createdAt?: any;
    createdBy: ScanTokenDTO.createdBy;
    distributedOn?: string;
    distributedTo?: any;
    transactionId?: string;
    transaction?: any;
};

export namespace ScanTokenDTO {

    export enum createdBy {
        ADMINISTRATOR = 'ADMINISTRATOR',
        CONFIRMED_SCAN_PROMOTION = 'CONFIRMED_SCAN_PROMOTION',
        PAYMENT_SYSTEM = 'PAYMENT_SYSTEM',
    }


}

