/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AnalysisQueryExportDTO = {
    code?: string;
    confirmedResult?: string;
    createdAtDateStart?: string;
    createdAtDateEnd?: string;
    name?: string;
    multipleStatus?: Array<'IDLE' | 'PROCESSING' | 'COMPLETED' | 'ERROR' | 'CONFIRMED'>;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
    sortOrder?: AnalysisQueryExportDTO.sortOrder;
    status?: AnalysisQueryExportDTO.status;
    userId?: string;
    userEmail?: string;
    userFirstName?: string;
    userLastName?: string;
    typeExport?: AnalysisQueryExportDTO.typeExport;
};

export namespace AnalysisQueryExportDTO {

    export enum sortOrder {
        ASC = 'asc',
        DESC = 'desc',
    }

    export enum status {
        IDLE = 'IDLE',
        PROCESSING = 'PROCESSING',
        COMPLETED = 'COMPLETED',
        ERROR = 'ERROR',
        CONFIRMED = 'CONFIRMED',
    }

    export enum typeExport {
        CSV = 'csv',
        PDF = 'pdf',
    }


}

