/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateWorkspaceDTO = {
    country?: string;
    description?: string;
    name: string;
    status?: UpdateWorkspaceDTO.status;
    type?: UpdateWorkspaceDTO.type;
    phoneNumber?: string;
    email?: string;
};

export namespace UpdateWorkspaceDTO {

    export enum status {
        ACTIVE = 'ACTIVE',
        ARCHIVED = 'ARCHIVED',
        DELETED = 'DELETED',
    }

    export enum type {
        B2B = 'B2B',
        B2C = 'B2C',
    }


}

